<template>
    <div class="page-invoice">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li><router-link to="/dashboard/inbounds">Inbounds</router-link></li>
                <li class="is-active"><router-link :to="{ name: 'Inbound', params: { id: inbound.id }}" aria-current="true">{{ inbound.inbound_number }}</router-link></li>
            </ul>
        </nav>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Inbound - {{ inbound.inbound_number }}</h1>

                <div class="buttons">
                    <button @click="getPdf()" class="button is-dark">Download label PDF</button>
                    <button @click="printPdf()" class="button is-dark">Print Labels</button>

                    <!-- <template v-if="!invoice.is_credit_for && !invoice.is_credited">
                        <button @click="setAsPaid()" class="button is-success" v-if="!invoice.is_paid">Set as paid</button>
                        <button @click="createCreditNote()" class="button is-danger" v-if="!invoice.is_paid">Create credit note</button>
                    </template>

                    <button @click="sendReminder()" class="button is-info" v-if="!invoice.is_paid && !invoice.is_credit_for">Send reminder</button> -->
                </div>

                <!-- <router-link :to="{ name: 'EditInbound', params: { id: inbound.id }}" class="button is-light mt-4">Edit Inbound</router-link> -->

            </div>

            <div class="column is-6 mb-4">
                <div class="box">
                    <h3 class="is-size-4 mb-5">Client</h3>

                    <p><strong>{{client.business_name }}</strong></p>
                    <p>{{client.contact_person}}</p>
                    <p>{{client.address1 }}</p>
                    <p v-if="client.address2">{{client.address2 }}</p>
                    <p>{{client.city }} {{ client.province }} {{ client.zipcode }}</p>
                    
                    <p style="padding-top: 5px;">{{ client.email }}</p>
                    <p>{{ client.phone }}</p>
                </div>
            </div>
            <div class="column is-6 mb-4">
                <div class="box mt-4" v-if="location">
                    <p><strong>{{ location.name }}</strong></p>
                    <p>{{ location.address1 }}</p>
                    <p v-if="location.address2">{{ selectedLocation.address2 }}</p>
                    <p>{{ location.city }} {{ location.province }} {{ location.zipcode }}</p>
                    <p style="padding-top: 5px;">{{ location.email }}</p>
                    <p>{{ location.phone }}</p>
                </div>    
            </div>

            <div class="column is-12 mb-4">
                <div class="box">
                    <h3 class="is-size-4 mb-5">Pallets</h3>

                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>Commodity</th>
                                <th>Box Count</th>
                                <!--  <th>Vat rate</th>
                                <th>Total</th> -->
                            </tr>
                        </thead>

                        <tbody>
                            <tr 
                                v-for="pallet in inbound.inbound_pallets"
                                v-bind:key="pallet.id"
                            >
                                <td>{{ pallet.commodity }}</td>
                                <td>{{ pallet.box_count }}</td>
                                <!-- <td>{{ item.vat_rate }}%</td>
                                <td>{{ getItemTotal(item) }}</td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="column is-12">
                <div class="box">
                    <h3 class="is-size-4 mb-5">Summary</h3>

                    <div class="columns">
                        <div class="column is-6">
                            <p><strong>Transport Company</strong>: {{ inbound.transport_company }}</p>
                            <p><strong>Container/Truck #</strong>: {{ inbound.truck_container_number }}</p>
                            <p><strong>BOL #</strong>: {{ inbound.bol_number }}</p>
                            <p><strong>Comment</strong>: {{ inbound.sender_reference }}</p>
                        </div>
                    
                        <div class="column is-6">
                            <p><strong>Total boxes</strong>: {{ inbound.total_boxes }}</p>
                            <p><strong>Total pallets</strong>: {{ inbound.inbound_pallets ? inbound.inbound_pallets.length : 0 }}</p>
                            <!-- <p><strong>Space usage</strong>: {{ inbound.space_usage ? inbound.space_usage : 'N/A' }}</p> -->
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="column is-12">
                <div class="buttons" style="margin-top: 100px; display: flex; justify-content: flex-end;">
                    <button @click="deleteInbound(inbound.id)" class="button is-danger is-small">Delete Inbound</button>
                </div>
            </div> -->

        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'

    const fileDownload = require('js-file-download')

    export default {
        name: 'Inbound',
        data() {
            return {
                client: {},
                location: {},
                inbound: {},
                pallets: []
            }
        },
        mounted() {
            this.getInbound()
        },
        methods: {
            getInbound() {
                const inboundID = this.$route.params.id

                axios
                    .get(`/api/v1/inbounds/${inboundID}`)
                    .then(response => {
                        this.inbound = response.data

                        axios
                            .get(`/api/v1/clients/${this.inbound.client}`)
                            .then(response => {
                                this.client = response.data
                            })
                            .catch(error => {
                                console.log(JSON.stringify(error))
                            })
                        
                        axios
                        .get(`/api/v1/locations/${this.inbound.location}`)
                        .then(response => {
                            this.location = response.data
                        })
                        .catch(error => {
                            console.log(JSON.stringify(error))
                        })

                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                    })

                
            },

            // deleteInbound(inboundId) {
            //     axios
            //         .delete(`/api/v1/inbounds/${inboundId}`)
            //         .then(() => {
            //             toast({
            //                 message: 'Inbound deleted successfully!!!',
            //                 type: 'is-success',
            //                 dismissible: true,
            //                 pauseOnHover: true,
            //                 duration: 2000,
            //                 position: 'bottom-right',
            //             })
            //             this.$router.push('/dashboard/inbounds')
            //         })
            //         .catch((error) => {
            //             console.error('Error deleting inbound:', error);
            //         });
            // },

            getPdf() {
                const inboundID = this.$route.params.id

                axios
                    .get(`/api/v1/inbounds/${inboundID}/generate_pdf/`, {
                        responseType: 'blob',
                    }).then(res => {
                        fileDownload(res.data, `inbound_labels_${inboundID}.pdf`);
                    }).catch(err => {
                        console.log(err);
                    })
            },
            printPdf() {
    const inboundID = this.$route.params.id;

    axios
        .get(`/api/v1/inbounds/${inboundID}/generate_pdf/`, {
            responseType: 'blob',
        }).then(res => {
            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
            const pdfURL = URL.createObjectURL(pdfBlob);

            const pdfWindow = window.open();
            pdfWindow.document.write('<html><head><title>Print</title></head><body style="margin:0;"><embed width="100%" height="100%" src="' + pdfURL + '"></body></html>');
            pdfWindow.document.close();

            // Wait for the PDF to load before printing
            pdfWindow.onload = function() {
                pdfWindow.print();
                URL.revokeObjectURL(pdfURL); // Release the object URL
            };
        }).catch(err => {
            console.log(err);
        });
}

            // getStatusLabel() {
            //     if (this.invoice.is_paid) {
            //         return 'Is paid'
            //     } else {
            //         return 'Is not paid'
            //     }
            // },
            // getInvoiceType() {
            //     if (this.invoice.invoice_type === 'credit_note') {
            //         return 'Credit note'
            //     } else {
            //         return 'Invoice'
            //     }
            // },
            // getItemTotal(item) {
            //     const unit_price = item.unit_price
            //     const quantity = item.quantity
            //     const total = item.net_amount + (item.net_amount * (item.vat_rate / 100))

            //     return parseFloat(total).toFixed(2)
            // },
            // async setAsPaid() {
            //     this.invoice.is_paid = true

            //     let items = this.invoice.items

            //     delete this.invoice['items']

            //     await axios
            //         .patch(`/api/v1/invoices/${this.invoice.id}/`, this.invoice)
            //         .then(response => {
            //             toast({
            //                 message: 'The changes was saved',
            //                 type: 'is-success',
            //                 dismissible: true,
            //                 pauseOnHover: true,
            //                 duration: 2000,
            //                 position: 'bottom-right',
            //             })
            //         })
            //         .catch(error => {
            //             console.log(JSON.stringify(error))
            //         })
                
            //     this.invoice.items = items
            // },
            // async createCreditNote() {
            //     this.invoice.is_credited = true

            //     let items = this.invoice.items

            //     delete this.invoice['items']

            //     await axios
            //         .patch(`/api/v1/invoices/${this.invoice.id}/`, this.invoice)
            //         .then(response => {
            //             toast({
            //                 message: 'The changes was saved',
            //                 type: 'is-success',
            //                 dismissible: true,
            //                 pauseOnHover: true,
            //                 duration: 2000,
            //                 position: 'bottom-right',
            //             })
            //         })
            //         .catch(error => {
            //             console.log(JSON.stringify(error))
            //         })
                
            //     this.invoice.items = items
                
            //     let creditNote = this.invoice
            //     creditNote.is_credit_for = this.invoice.id
            //     creditNote.is_credited = false
            //     creditNote.invoice_type = 'credit_note'

            //     delete creditNote['id']

            //     await axios
            //         .post('api/v1/invoices/', creditNote)
            //         .then(response => {
            //             toast({
            //                 message: 'The credit note was created',
            //                 type: 'is-success',
            //                 dismissible: true,
            //                 pauseOnHover: true,
            //                 duration: 2000,
            //                 position: 'bottom-right',
            //             })

            //             this.$router.push('/dashboard/invoices')
            //         })
            //         .catch(error => {
            //             console.log(JSON.stringify(error))
            //         })
            // },
            // sendReminder() {
            //     axios
            //         .get(`/api/v1/invoices/${this.invoice.id}/send_reminder/`)
            //         .then(response => {
            //             toast({
            //                 message: 'The reminder was sent',
            //                 type: 'is-success',
            //                 dismissible: true,
            //                 pauseOnHover: true,
            //                 duration: 2000,
            //                 position: 'bottom-right',
            //             })
            //         })
            //         .catch(error => {
            //             console.log(JSON.stringify(error))
            //         })
            // }
        }
    }
</script>