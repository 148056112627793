<template>
    <div class="page-edit-invoice">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li><router-link to="/dashboard/invoices">Invoices</router-link></li>
                <li><router-link :to="{ name: 'Invoice', params: { id: invoice.id }}">{{ invoice.invoice_number }}</router-link></li>
                <li class="is-active"><router-link :to="{ name: 'EditInvoice', params: { id: invoice.id }}" aria-current="true">Edit</router-link></li>
            </ul>
        </nav>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Edit invoice</h1>
            </div>

            <div class="column is-12">
                <h2 class="is-size-5 mb-4">Invoice</h2>

                <div class="select">
                    <select name="client" v-model="invoice.client">
                        <option value="">- Select client -</option>
                        <option 
                            v-for="client in clients"
                            :key="client.id"
                            :value="client.id"
                        >
                            {{client.business_name }}
                        </option>
                    </select>
                </div>

                <div class="box mt-4" v-if="invoice.client">
                    <p><strong>{{ selectedClient.business_name }}</strong></p>
                    <p>{{ selectedClient.contact_person}}</p>
                    <p>{{ selectedClient.address1 }}</p>
                    <p v-if="selectedClient.address2">{{ selectedClient.address2 }}</p>
                    <p>{{ selectedClient.city }} {{ selectedClient.province }} {{ selectedClient.zipcode }}</p>
                    
                    <p style="padding-top: 5px;">{{ selectedClient.email }}</p>
                    <p>{{ selectedClient.phone }}</p>
                </div>

            </div>

            <div class="column is-12">
                <h2 class="is-size-5 mb-4">Items</h2>

                <ItemForm
                    v-for="item in invoice.items"
                    :key="item.id"
                    :initialItem="item"
                    @updatePrice="updateTotals"
                    @deleteItem="handleDeleteItem"
                >
                </ItemForm>
                <button class="button is-light" @click="addItem">+</button>
            </div>

            <div class="column is-12">
                <h2 class="is-size-5 mb-4">Misc</h2>

                <div class="field">
                    <label>Due days</label>

                    <div class="control">
                        <input type="number" class="input" v-model="invoice.due_days">
                    </div>
                </div>

                <div class="field">
                    <label>Sender reference</label>

                    <div class="control">
                        <input type="text" class="input" v-model="invoice.sender_reference">
                    </div>
                </div>
            </div>
        </div>

        <div class="column is-12">
            <h2 class="is-size-5 mb-4">Total</h2>

            <p><strong>Net amount</strong>: {{ invoice.net_amount }}</p>
            <p><strong>Vat amount</strong>: {{ invoice.vat_amount }}</p>
            <p><strong>Gross amount</strong>: {{ invoice.gross_amount }}</p>
        </div>

        <div class="column is-12">
            <button class="button is-success" @click="submitForm">Save</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import ItemForm from '@/components/ItemForm.vue'

export default {
    name: 'EditInvoice',
    components: {
        ItemForm
    },
    data() {
        return {
            invoice: {
                client: '',
                items: [
                    {
                        title: '',
                        unit_price: '',
                        quantity: 1,
                        vat_rate: 0,
                        net_amount: 0
                    }
                ],
                due_days: 14, 
                net_amount: 0,
                vat_amount: 0,
                gross_amount: 0
            },
            clients: []
        }
    },
    async mounted() {
        await this.getClients()
        // Fetch invoice data based on the route parameter (ID)
        await this.fetchInvoiceData();
    },
    methods: {
        async fetchInvoiceData() {
            try {
                const invoiceId = this.$route.params.id;
                const response = await axios.get(`/api/v1/invoices/${invoiceId}`);
                this.invoice = response.data;
            } catch (error) {
                console.error('Error fetching invoice data:', error);
            }
        },
        getClients() {
            axios
                .get('/api/v1/clients/')
                .then(response => {
                    this.clients = response.data
                })
                .catch(error => {
                    console.error('Error fetching clients:', error);
                })
        },
        addItem() {
            this.invoice.items.push({
                // item_num: this.invoice.items.length,
                title: '',
                unit_price: '',
                quantity: 1,
                vat_rate: 13,
                net_amount: 0
            })
        },
        handleDeleteItem(deletedItem) {
            // Implement your logic to delete the item from the array
            this.invoice.items = this.invoice.items.filter(item => item.id !== deletedItem.id);
        },
        updateTotals(changedItem) {
            let net_amount = 0;
            let vat_amount = 0;

            // Update the item in this.invoice.items array or add it if not found
            const updatedItems = this.invoice.items.map(item => {
                if (item.id === changedItem.id) {
                    // console.log('matched item num:', item.id);
                    // console.log('Updating existing item:', changedItem);
                    return changedItem;
                }
                // console.log('Existing item:', item);
                return item;
            });

            // If the changedItem is not found in the existing items, add it
            if (!this.invoice.items.some(item => item.id === changedItem.id)) {
                // console.log('Adding new item:', changedItem);
                updatedItems.push(changedItem);
            }

            // Log the updatedItems array for further inspection
            // console.log('Updated items array:', updatedItems);

            // Assign the updatedItems array to this.invoice.items
            this.invoice.items = updatedItems;

            // Recalculate totals
            this.invoice.items.forEach(item => {
                const vat_rate = item.vat_rate;
                vat_amount += item.net_amount * (vat_rate / 100);
                net_amount += item.net_amount;
            });
            
            this.invoice.net_amount = net_amount;
            this.invoice.vat_amount = parseFloat(vat_amount).toFixed(2);
            this.invoice.gross_amount = net_amount + vat_amount;
            this.invoice.discount_amount = 0;
        },
        submitForm() {
            // Update the existing invoice using HTTP PUT
            // console.log(this.selectedClient);
            this.invoice.client_business_name = this.selectedClient.business_name
            this.invoice.client_email = this.selectedClient.email
            // this.invoice.client_org_number = this.selectedClient.org_number
            this.invoice.client_address1 = this.selectedClient.address1
            this.invoice.client_address2 = this.selectedClient.address2
            this.invoice.client_zipcode = this.selectedClient.zipcode
            this.invoice.client_city = this.selectedClient.city
            this.invoice.client_province = this.selectedClient.province
            this.invoice.client_country = this.selectedClient.country
            this.invoice.client_contact_person = this.selectedClient.contact_person
            this.invoice.sender_reference = this.selectedClient.sender_reference
            this.invoice.client = this.selectedClient.id
           
            axios
                .put(`/api/v1/invoices/${this.invoice.id}/`, this.invoice)
                .then(response => {
                    toast({
                        message: 'The invoice was updated',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })

                    this.$router.push('/dashboard/invoices')
                })
                .catch(error => {
                    console.error('Error updating invoice:', error);
                })
        }
    },
    computed: {
        selectedClient() {
            // Find the selected client in the clients array
            return this.clients.find(client => client.id === this.invoice.client) || {};
        }
    }

}
</script>

<style lang="scss">
    .select, .select select {
        width: 100%;
    }
</style>