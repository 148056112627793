<template>
    <div class="columns">
        
        <div class="column is-1" style="text-align: right;">
            {{ sequenceNumber }}
        </div>
        <div class="column is-3">
            <div class="field">
                <label>Commodity</label>
                <div class="control">
                    <input type="text" maxlength="20" class="input" v-model="pallet.commodity">
                </div>
            </div>
        </div>

        <div class="column is-2">
            <div class="field">
                <label>Box Count</label>
                <div class="control">
                    <input type="number" min="1" max="64" class="input" v-model="pallet.box_count" @input="handleBoxCountChange">
                </div>
            </div>
        </div>

        <div class="column is-1">
            <button class="button is-danger" @click="deletePallet"><svg-icon type="mdi" :path="path"></svg-icon></button>
        </div>

    </div>
</template>

<style scoped>
  /* Align columns vertically using Flexbox */
  .columns {
    display: flex;
    align-items: flex-end;
  }

  /* Optional: Adjust the spacing between text boxes and the delete button */
  .column:not(:last-child) {
    margin-right: 1rem;
  }
</style>

<script>
import {mdiDelete} from '@mdi/js'
import SvgIcon from '@jamescoyle/vue-icon'

export default {
    name: 'PalletForm',
    components: {
        SvgIcon
    },
    props: {
        initialPallet: Object,
        sequenceNumber:Number
    },
    data() {
        return {
            pallet: this.initialPallet,
            path: mdiDelete
        }
    },
    methods: {
        handleBoxCountChange() {
            // console.log("***test", this.pallet);
            this.pallet.box_count = parseInt(this.pallet.box_count, 10);
            this.$emit('updateBoxCount', this.pallet);
        },
        deletePallet() {
           this.$emit('deletePallet', this.pallet);
        }
    }
}
</script>