<template>
    <div class="page-invoice">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li><router-link to="/dashboard/outbounds">Outbounds</router-link></li>
                <li class="is-active"><router-link :to="{ name: 'Outbound', params: { id: outbound.id }}" aria-current="true">{{ outbound.id }}</router-link></li>
            </ul>
        </nav>

        <div class="columns is-multiline">
            <div class="column is-4">
                <h1 class="title">Outbound </h1>
            </div>
            <div class="column is-4">
                <!-- <h1 class="title">Outbound - {{ outbound.id }}</h1> -->
                <div class="buttons">
                    <button @click="getPdf()" class="button is-dark">Packaging Slip (PDF)</button>
                </div>
            </div>
            <div class="column is-4">
                <div class="buttons">
                    <button @click="getBOLPdf()" class="button is-success">Bill of Landing (PDF)</button>
                </div>
            </div>
            

            <div class="column is-4 mb-4">
                <div class="box">
                    <h3 class="is-size-4 mb-5">Client</h3>

                    <p><strong>{{client.business_name }}</strong></p>
                    <p>{{client.contact_person}}</p>
                    <p>{{client.address1 }}</p>
                    <p v-if="client.address2">{{client.address2 }}</p>
                    <p>{{client.city }} {{ client.province }} {{ client.zipcode }}</p>
                    
                    <p style="padding-top: 5px;">{{ client.email }}</p>
                    <p>{{ client.phone }}</p>
                </div>
            </div>

            <div class="column is-4 mb-4">
                <div class="box">
                    <h3 class="is-size-4 mb-5">Consignee</h3>

                    <p><strong>{{consignee.business_name }}</strong></p>
                    <p>{{consignee.address1 }}</p>
                    <p>{{consignee.city }} {{ consignee.province }} {{ consignee.zipcode }}</p>
                    <p style="padding-top: 5px;">{{ consignee.email }}</p>
                    <p>{{ consignee.phone }}</p>
                </div>
            </div>
            <div class="column is-4 mb-4">
                <div class="box mt-4">
                    <h3 class="is-size-4 mb-5">Carrier</h3>
                    <p>Vehicle No.: {{ outbound.carrier_company }}</p>
                    <p>Vehicle No.: {{ outbound.carrier_vehicle_number }}</p>
                    <p>Driver: {{ outbound.carrier_driver_name }}</p>
                    <p>Driver's Ph.: {{ outbound.carrier_driver_phone }}</p>
                    <p>POC: {{ outbound.carrier_poc }}</p>

                </div>    
            </div>
            <div class="column is-6 mb-4">
                <div class="box mt-4">
                    <h3 class="is-size-4 mb-5">Instructions</h3>
                    <p>Pickup Date: {{ outbound.instructions_pick_dt }}</p>
                    <p>Delivery Date.: {{ outbound.instructions_delivery_dt }}</p>
                    <p>Tailgate: {{ outbound.instructions_tailgate }}</p>
                    <p>Dock: {{ outbound.instructions_dock }}</p>
                </div>    
            </div>

            <div class="column is-6 mb-4">
                <div class="box mt-4">
                    <h3 class="is-size-4 mb-5">Freight Charges</h3>
                    <p>Is Paid?: {{ outbound.freight_is_paid }}</p>
                    <p>Collect (COD): {{ outbound.freight_collect_cod }}</p>
                    <p>Third Party: {{ outbound.freight_third_party }}</p>
                </div>    
            </div>

            <div class="column is-12">
                
                <table class="table table-bordered is-fullwidth">
                    <thead>
                        <tr>
                            <th @click="sort('client_business_name')">Commodity</th>
                            <th @click="sort('client_business_name')"># Boxes Dispatched</th>
                            <th></th>
                        </tr>
                    </thead>                 

                    <tbody>
                        <tr
                            v-for="pallet in this.pallets" v-bind:key="pallet.id"
                        >
                            <td>{{ pallet.commodity }}</td>
                            <td>{{ pallet.boxes_dispatched }}</td>
                            <td>
                                <router-link :to="{ name: 'Inbound', params: { id: pallet.inbound_id }}"> Inbound Details</router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- <div class="column is-12">
            <div class="buttons" style="margin-top: 100px; display: flex; justify-content: flex-end;">
                <button @click="deleteOutbound(outbound.id)" class="button is-danger is-small">Delete Outbound</button>
            </div>
            </div> -->


        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment';
    import { toast } from 'bulma-toast'

    const fileDownload = require('js-file-download')

    export default {
        name: 'Outbound',
        data() {
            return {
                client: {},
                consignee: {},
                outbound: {},
                dispatches: [],
                pallets: []
            }
        },
        mounted() {
            this.getOutbound()
        },
        methods: {
            async getOutbound() {
                const outboundID = this.$route.params.id
                try {
                        const response = await axios.get(`/api/v1/outbounds/${outboundID}`);
                        this.outbound = response.data;

                        const clientResponse = await axios.get(`/api/v1/clients/${this.outbound.client}`);
                        this.client = clientResponse.data;

                        const consigneeResponse = await axios.get(`/api/v1/clients/${this.outbound.consignee}`);
                        this.consignee = consigneeResponse.data;

                        for (let j = 0; j < this.outbound.dispatched_pallets.length; j++) {

                            let pallet = {};
                            pallet['boxes_dispatched'] = this.outbound.dispatched_pallets[j].boxes_dispatched
                            const plid = this.outbound.dispatched_pallets[j].pallet;
                            pallet['pallet_id'] = plid;

                            const palletResponse = await axios.get(`/api/v1/pallets/${plid}/`);   
                            pallet['commodity'] = palletResponse.data.commodity;
                            pallet['inbound_id'] = palletResponse.data.inbound_id
                            this.pallets.push(pallet);

                        }
                        // console.log(this.pallets);
                    }catch (error) {
                        console.log(JSON.stringify(error));
                }
            },
            // deleteOutbound(outboundId) {
            //     axios
            //         .delete(`/api/v1/outbounds/${outboundId}`)
            //         .then(() => {
            //             toast({
            //                 message: 'Outbound deleted successfully!!!',
            //                 type: 'is-success',
            //                 dismissible: true,
            //                 pauseOnHover: true,
            //                 duration: 2000,
            //                 position: 'bottom-right',
            //             })
            //             this.$router.push('/dashboard/outbounds')
            //         })
            //         .catch((error) => {
            //             console.error('Error deleting outbound:', error);
            //         });
            // },

            getFormattedDate(date) {
                return moment(date).format("DD-MM-YYYY HH:MM")
            },

            getPdf() {
                const outboundID = this.$route.params.id

                axios
                    .get(`/api/v1/outbounds/${outboundID}/generate_packaging_pdf/`, {
                        responseType: 'blob',
                    }).then(res => {
                        fileDownload(res.data, `${this.client.business_name} - Dispatch Order ${outboundID}.pdf`);
                    }).catch(err => {
                        console.log(err);
                    })
            },
            getBOLPdf() {
                const outboundID = this.$route.params.id

                axios
                    .get(`/api/v1/outbounds/${outboundID}/generate_bol_pdf/`, {
                        responseType: 'blob',
                    }).then(res => {
                        fileDownload(res.data, `${this.client.business_name} - Bill of Landing ${outboundID}.pdf`);
                    }).catch(err => {
                        console.log(err);
                    })
            },
        }
    }
</script>