<template>

    <div class="analytics-page"  >
      <div class="analytics-grid" v-if="$store.state.isAuthenticated">
        <router-link to="/dashboard/inbounds" class="analytics-link">
          <div class="analytics-box">
            <span class="analytics-value">{{ numPallets }}</span>
            <span class="analytics-label">Pallets at Hand</span>
          </div>
        </router-link>
        <router-link to="/dashboard/clients" class="analytics-link">
          <div class="analytics-box">
            <span class="analytics-value">{{ numClients }}</span>
            <span class="analytics-label">Clients</span>
          </div>
        </router-link>
        <router-link to="/dashboard/outbounds" class="analytics-link">
          <div class="analytics-box">
            <span class="analytics-value">{{ numOutboundsToday }}</span>
            <span class="analytics-label">Outbound Pallets Today</span>
          </div>
        </router-link>
        <router-link to="/dashboard/inbounds" class="analytics-link">
          <div class="analytics-box">
            <span class="analytics-value">{{ numInboundsToday }}</span>
            <span class="analytics-label">Inbound Pallets Today</span>
          </div>
        </router-link>
      </div>
      <div v-else>
        <h1>Welcome to ValueBox</h1>
      </div>
    </div>

</template>


<script>
import axios from 'axios'
export default {
  name: 'Home',
  data() {
    return {
      numPallets:0,
      numClients: 0,
      numOutboundsToday:0,
      numInboundsToday: 0,
    };
  },
  mounted() {
    this.getInbounds(),
    this.getClients(),
    this.getOutbounds()
  },
  methods: {
        getInbounds() {
            axios
                .get('/api/v1/inbounds/')
                .then(response => {
                  let tot_pallets = 0
                  const estDate = new Date().toLocaleDateString("en-US", {timeZone: "America/New_York", year: 'numeric', month: '2-digit', day: '2-digit'});
                  const [month, day, year] = estDate.split("/");
                  const today = `${month}/${day}/${year}`;

                  for (let i = 0; i < response.data.length; i++) {
                    let inbound = response.data[i]
                    for (let k=0; k < inbound.inbound_pallets.length; k++) {
                        let pallet = inbound.inbound_pallets[k];

                        pallet['available_boxes'] = pallet.box_count - pallet.total_boxes_dispatched
                        if (pallet['available_boxes'] > 0){
                          tot_pallets += 1;
                        }
                    }

                    const djangoCreatDate = new Date(response.data[i].created_at); // This is the date from Django
                    let createDate = djangoCreatDate.toLocaleDateString("en-US", {timeZone: "America/New_York", year: 'numeric', month: '2-digit', day: '2-digit'});
                    if (createDate === today) {
                      console.log("equal: ", createDate, today);
                      this.numInboundsToday += response.data[i].total_pallets;
                    } else{
                      console.log("not equal: ", createDate, today);
                    }
                  }
                  this.numPallets = tot_pallets

                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        getOutbounds() {
            axios
                .get('/api/v1/outbounds/')
                .then(response => {
                  const estDate = new Date().toLocaleDateString("en-US", {timeZone: "America/New_York", year: 'numeric', month: '2-digit', day: '2-digit'});
                  const [month, day, year] = estDate.split("/");
                  const today = `${month}/${day}/${year}`;

                  for (let i = 0; i < response.data.length; i++) {
                    const djangoCreatDate = new Date(response.data[i].created_at); // This is the date from Django
                    let createDate = djangoCreatDate.toLocaleDateString("en-US", {timeZone: "America/New_York", year: 'numeric', month: '2-digit', day: '2-digit'});
                    
                      if (createDate === today) {
                        this.numOutboundsToday += response.data[i].dispatched_pallets.length;
                      }
                  }
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        getClients() {
            axios
                .get('/api/v1/clients/')
                .then(response => {
                    this.numClients = response.data.length
                    // for (let i = 0; i < response.data.length; i++) {
                    //     this.inbounds.push(response.data[i])
                    // }
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
  }
};
</script>

<style scoped>
.analytics-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.analytics-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 800px; /* Adjust as needed */
  margin: 0 auto;
}

.analytics-box {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.analytics-box:hover {
  background-color: #e0e0e0;
}

.analytics-link {
  text-decoration: none;
  color: inherit;
}

.analytics-value {
  font-size: 24px;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

.analytics-label {
  font-size: 14px;
  color: #888;
  font-style: italic;
}
</style>