<template>
    <div class="page-inbounds">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li class="is-active"><router-link to="/dashboard/inbounds" aria-current="true">Inbounds</router-link></li>
            </ul>
        </nav>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Inbounds</h1> 
            </div>
            <div class="column is-9">
                <button class="button is-success"> <router-link to="/dashboard/inbounds/add" class="button is-success">Add inbound</router-link></button> 
            </div>

            <div class="column is-3">
                <input class="input is-small" type="search" v-model="filter" placeholder="Search inbounds by business name">
            </div>
            
            <div class="column is-12">
                
                <table class="table table-bordered is-fullwidth">
                    <thead>
                        <tr>
                            <th @click="sort('created_at')" >Date</th>
                            <th @click="sort('client_business_name')">Business</th>
                            <th @click="sort('client_contact_person')">POC</th>
                            <!-- <th @click="sort('client_commodity')">Commodity</th> -->
                            <th @click="sort('total_pallets')"># Pallets</th>
                            <th @click="sort('total_boxes')"># Boxes</th>
                            <th></th>
                        </tr>
                    </thead>                 

                    <tbody>
                        <tr
                            v-for="inbound in sortedInbounds"
                            v-bind:key="inbound.id"
                        >
                            <td>{{ getFormattedDate(inbound.created_at) }}</td>
                            <td>{{ inbound.client_business_name }}</td>
                            <td>{{ inbound.client_contact_person }}</td>
                            <!-- <td></td>{{ inbound.client_commodity }}</td> -->
                            <td>{{ inbound.total_pallets }}</td>
                            <td>{{ inbound.total_boxes}}</td>
                            <!-- <td>{{ inbound }}</td> -->
                            <!-- <td>{{ getStatusLabel(invoice) }}</td> -->
                            <td>
                                <router-link :to="{ name: 'Inbound', params: { id: inbound.id }}">Details</router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- debug: sort={{currentSort}}, dir={{currentSortDir}} -->

                <div class="buttons">
                    <button class="button is-link is-inverted" @click="prevPage">Previous</button> 
                    <span> </span>
                    <button class="button is-link is-inverted" @click="nextPage">Next</button>
                </div>

            </div>


        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment';


export default {
    name: 'Inbounds',
    data() {
        return {
            inbounds: [],
            currentSort:'created_at',
            currentSortDir:'desc',
            pageSize:20,
            currentPage:1, 
            filter: ''
        }
    },
    mounted() {
        this.getInbounds()
    },
    methods: {
        getInbounds() {
            axios
                .get('/api/v1/inbounds/')
                .then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        this.inbounds.push(response.data[i])
                    }
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        getFormattedDate(date) {
            return moment(date).format("YYYY-MM-DD HH:mm")
        },
        sort:function(s) {
            //if s == current sort, reverse
            if(s === this.currentSort) {
            this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
            }
            this.currentSort = s;
        },
        nextPage:function() {
            if((this.currentPage*this.pageSize) < this.inbounds.length) this.currentPage++;
        },
        prevPage:function() {
            if(this.currentPage > 1) this.currentPage--;
        }
    },
    computed:{
        sortedInbounds:function() {
            return this.filteredInbounds.sort((a,b) => {
                let modifier = 1;
                if(this.currentSortDir === 'desc') modifier = -1;
                if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                return 0;
            }).filter((row, index) => {
                let start = (this.currentPage-1)*this.pageSize;
                let end = this.currentPage*this.pageSize;
                if(index >= start && index < end) return true;
            });
        },
        filteredInbounds() {
            return this.inbounds.filter(c => {
                if(this.filter == '') return true;
                return c.client_business_name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0;
            })
        },
    },
}
</script>