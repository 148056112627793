<template>
    <div class="page-clients">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li class="is-active"><router-link to="/dashboard/clients" aria-current="true">Clients</router-link></li>
            </ul>
        </nav>

        <h1 class="title">Clients</h1>
        <div class="columns is-multiline">
            <div class="column is-12">
                <router-link :to="{ name: 'AddClient' }" class="button is-dark mt-4">Add client</router-link>
                
                <button style="margin-left: 5px;" class="button is-info mt-4" @click="isListView = !isListView">
                    {{ isListView ? 'Switch to Grid View' : 'Switch to List View' }}
                </button>
            </div>

            <div class="column is-3">
                <input class="input" type="search" v-model="filter" placeholder="Search Clients by business name">
            </div>

            <div class="column is-8">
                
                <label>
                <input type="radio" value="true" v-model="is_active_client" /> Active
                </label>
                <label>
                <input type="radio" value="false" v-model="is_active_client" /> Inactive
                </label>
                <label>
                <input type="radio" value="all" v-model="is_active_client" /> All
                </label>
            </div>

            
            <!-- List view -->
            <div v-if="isListView" class="column is-12" style="margin: 10px;">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th @click="sort('business_name')">Business Name</th>
                            <th @click="sort('contact_person')">Contact Person</th>
                            <th>Phone</th>
                            <th @click="sort('total_pallet_count')"># Pallets</th>
                            <th @click="sort('total_boxes_count')"># Boxes</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="client in this.sortedClients" v-bind:key="client.id">
                            <td>{{ client.business_name }}</td>
                            <td>{{ client.contact_person }}</td>
                            <td>{{ client.phone }}</td>
                            <td style="text-align: center;">{{ client.total_pallet_count }}</td>
                            <td style="text-align: center">{{ client.total_boxes_count }}</td>
                            <td><router-link :to="{ name: 'Client', params: { id: client.id }}">Details</router-link></td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <div
                class="column is-3"
                v-for="client in sortedClients"
                v-bind:key="client.id"
                v-else
            >
                <div class="box">
                    <h3 class="is-size-4 mb-4">{{ client.business_name }}</h3>
                    <p>{{ client.contact_person}}</p>

                    <router-link :to="{ name: 'Client', params: { id: client.id }}" class="button is-light">Details</router-link>
                </div>
            </div>

                <div class="buttons">
                    <button class="button is-link is-inverted" @click="prevPage">Previous</button> 
                    <span> </span>
                    <button class="button is-link is-inverted" @click="nextPage">Next</button>
                </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Clients',
    data() {
        return {
            clients: [],
            total_boxes_count: 0,
            total_pallet_count: 0,
            isListView: true,
            currentSort:'business_name',
            currentSortDir:'asc',
            pageSize:10,
            currentPage:1, 
            filter: '',
            is_active_client: 'true'
        }
    },
    mounted() {
        this.getClients()
    },
    methods: {
        getClients() {
            axios
                .get('/api/v1/clients/')
                .then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        let client = response.data[i]
                        client['total_pallet_count'] = 0
                        client['total_boxes_count'] = 0
                        for (let j = 0; j < client.inbounds.length; j++) { 
                            let inbound = client.inbounds[j]

                            for (let k=0; k < inbound.inbound_pallets.length; k++) {
                                let pallet = inbound.inbound_pallets[k];
                            
                                pallet['available_boxes'] = pallet.box_count - pallet.total_boxes_dispatched
                                if (pallet['available_boxes'] > 0){
                                    client['total_pallet_count'] += 1;
                                    client['total_boxes_count'] += pallet['available_boxes'];
                                }
                            }
                            // client['total_pallet_count'] += inbound.total_pallets
                            
                        }                        
                        this.clients.push(client)

                        // console.log(client);
                    }
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        sort:function(s) {
            //if s == current sort, reverse
            if(s === this.currentSort) {
            this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
            }
            this.currentSort = s;
        },
        nextPage:function() {
            if((this.currentPage*this.pageSize) < this.clients.length) this.currentPage++;
        },
        prevPage:function() {
            if(this.currentPage > 1) this.currentPage--;
        }
    },
    computed:{
        sortedClients:function() {
            return this.filteredClients.sort((a,b) => {
                let modifier = 1;
                if(this.currentSortDir === 'desc') modifier = -1;
                if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                return 0;
            }).filter((row, index) => {
                let start = (this.currentPage-1)*this.pageSize;
                let end = this.currentPage*this.pageSize;
                if(index >= start && index < end) return true;
            });
        },

        // filteredClients() {
        //     return this.clients.filter(c => {
        //         if(this.filter == '') return true;
        //         return c.business_name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0;
        //     })
        // },
        filteredClients() {
            return this.clients.filter(client => {
                const matchesStatus = this.is_active_client === 'all' || String(client.is_active) === this.is_active_client;
                const matchesFilter = this.filter === '' || client.business_name.toLowerCase().includes(this.filter.toLowerCase());
                return matchesStatus && matchesFilter;
            });
        },
    },
}
</script>