<template>
    <div class="page-my-account">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li class="is-active"><router-link to="/dashboard/my-account" aria-current="true">{{team.business_name}} account</router-link></li>
            </ul>
        </nav>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Account Information</h1>
                <h2 class="subtitle is-size-3 mb-3">{{team.business_name }}</h2>
    
                <p v-if="team.business_number">Business Number: {{ team.business_number }}</p>
                <p v-if="team.gst_number">GST Number: {{ team.gst_number }}</p>
    
                <router-link to="/dashboard/my-account/upload-team-logo" class="button is-light">Upload/Replace Logo</router-link>

            </div>


            <div class="column is-6">
                <p>Username: {{ $store.state.user.username }} </p>
                <p>Email: {{team.email }} </p>
                <p>Phone: {{team.phone }} </p>

                <p>Email Notifications: {{emailNotificationStatus }} </p>

            </div>

            <div class="column is-6">
                <p>Contact Person: <strong>{{ team.contact_person }}</strong></p>

                <p v-if="team.address1">{{ team.address1 }}</p>
                <p v-if="team.address2">{{ team.address2 }}</p>
                <p v-if="team.zipcode || team.city">{{ team.city }} {{ team.province }} {{ team.zipcode }}</p>
                <!-- <p v-if="team.country">{{ team.country }}</p> -->
            </div>
            <router-link to="/dashboard/my-account/edit-team" class="button is-light">Edit team</router-link>
        </div>

    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'MyAccount', 
    data() {
        return {
            team: {}
        }
    },
    async mounted() {
        await this.getOrCreateTeam()  
    },
    methods: {
        getOrCreateTeam() {
            axios
                .get('/api/v1/teams/')
                .then(response => {
                    this.team = response.data[0]
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
    },
    computed: {
    emailNotificationStatus() {
      return this.team.email_notifications ? 'Yes' : 'No';
    }
  }
}
</script>