<template>
    <div class="page-dashboard">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Dashboard</h1>
            </div>

            <div class="column is-6">
                <div class="box">
                    <h2 class="subtitle">New Inbounds</h2>
                    <table class="table table-bordered is-fullwidth">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Business</th>
                            <th>POC</th>
                            <!-- <th @click="sort('total_pallets')">Pallets at Hand</th> -->
                            <th>Pallets at Hand</th>
                            <th>#Pallets (new)</th>
                        </tr>
                    </thead>                 

                    <tbody>
                        <tr
                            v-for="inbound in inbounds"
                            v-bind:key="inbound.id"
                        >
                            <td>{{ getFormattedDate(inbound.created_at) }}</td>
                            <td>{{ inbound.client_business_name }}</td>
                            <td>{{ inbound.client_contact_person }}</td>
                            <td>{{ getPalletsAtHand(inbound.client) }}</td>
                            <td>{{ inbound.total_pallets }}</td>
                            <!-- <td>{{ inbound.total_boxes}}</td> -->
                            <!-- <td>{{ inbound }}</td> -->
                            <!-- <td>{{ getStatusLabel(invoice) }}</td> -->
                            <td>
                                <router-link :to="{ name: 'Inbound', params: { id: inbound.id }}">Details</router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>

            <div class="column is-6">
                <div class="box">
                    <h2 class="subtitle">Newest clients</h2>

                    <div
                        v-for="client in limitedClients"
                        v-bind:key="client.id"
                    >
                        <div class="box mb-2">
                            <h3 class="is-size-4 mb-4">{{ client.business_name }}</h3>
                            <p>{{client.contact_person}}</p>
                            <p>{{client.phone}}</p>

                            <router-link :to="{ name: 'Client', params: { id: client.id }}" class="button is-light">Details</router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-6">
                <div class="box">
                    <h2 class="subtitle">New Outbounds</h2>

                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <!-- <th>#</th> -->
                                <th>Date</th>
                                <th>Client</th>
                                <th>Driver</th>
                                <th>Due date</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="outbound in this.outbounds"
                                v-bind:key="outbound.id"
                            >
                                <td>{{ getFormattedDate(outbound.created_at) }}</td>
                                <td>{{ outbound.client_business_name }}</td>
                                <td>{{ outbound.carrier_driver_name }}</td>
                                <!-- <td>{{ outbound.get_due_date_formatted }}</td> -->
                                <td>
                                    <router-link :to="{ name: 'Outbound', params: { id: outbound.id }}">Details</router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="column is-6">
                <div class="box">
                    <h2 class="subtitle">Unpaid invoices</h2>

                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Client</th>
                                <th>Amount</th>
                                <th>Due date</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="invoice in unpaidInvoices"
                                v-bind:key="invoice.id"
                            >
                                <td>{{ invoice.invoice_number }}</td>
                                <!-- <td>{{ invoice.client_name }}</td> -->
                                <td>{{ invoice.gross_amount }}</td>
                                <td>{{ invoice.get_due_date_formatted }}</td>
                                <td>
                                    <router-link :to="{ name: 'Invoice', params: { id: invoice.id }}">Details</router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment';
export default {
    name: 'Dashboard',
    data() {
        return {
            invoices: [],
            clients: [],
            inbounds: [],
            outbounds: []
        }
    },
    mounted() {
        this.getInvoices()
        this.getClients(),
        this.getInbounds(),
        this.getOutbounds()
    },
    methods: {
        getClients() {
            axios
                .get('/api/v1/clients/')
                .then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        let client = response.data[i]
                        client['total_pallet_count'] = 0
                        client['total_boxes_count'] = 0
                        for (let j = 0; j < client.inbounds.length; j++) { 
                            let inbound = client.inbounds[j]

                            for (let k=0; k < inbound.inbound_pallets.length; k++) {
                                let pallet = inbound.inbound_pallets[k];
                            
                                pallet['available_boxes'] = pallet.box_count - pallet.total_boxes_dispatched
                                if (pallet['available_boxes'] > 0){
                                    client['total_pallet_count'] += 1;
                                    client['total_boxes_count'] += pallet['available_boxes'];
                                }
                            }
                            // client['total_pallet_count'] += inbound.total_pallets
                            
                        }                        
                        this.clients.push(client)

                        // console.log(client);
                    }
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        getInbounds() {
            axios
                .get('/api/v1/inbounds/')
                .then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        this.inbounds.push(response.data[i])
                    }
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        async getOutbounds() {
            try {
                const response = await axios.get('/api/v1/outbounds/');
                for (let i = 0; i < response.data.length; i++) {
                    let outbound = response.data[i];
                    let pallet_ids = [];
                    let commodity = [];
                    let boxes_dispatched = [];
                    for (let j = 0; j < outbound.dispatched_pallets.length; j++) {
                        const plid = outbound.dispatched_pallets[j].pallet;
                        const palletResponse = await axios.get(`/api/v1/pallets/${plid}/`);
                        commodity.push(palletResponse.data.commodity);
                        pallet_ids.push(outbound.dispatched_pallets[j].pallet);
                        boxes_dispatched.push(outbound.dispatched_pallets[j].boxes_dispatched);
                    }
                    const clientResponse = await axios.get(`/api/v1/clients/${outbound.client}/`);
                    outbound['client_business_name'] = clientResponse.data.business_name;
                    outbound['commodity'] = commodity;
                    outbound['pallet_ids'] = pallet_ids;
                    outbound['boxes_dispatched'] = boxes_dispatched;
                    this.outbounds.push(outbound);
                }
            } catch (error) {
                console.log(JSON.stringify(error));
            }
        },
        getFormattedDate(date) {
            return moment(date).format("DD-MM-YY hh:mm")
        },
        getPalletsAtHand(client_id) {
            let client = this.clients ? this.clients.find(c => c.id === client_id) : null;
            return client ? client.total_pallet_count : 0;
        },
        getInvoices() {
            axios
                .get('/api/v1/invoices/')
                .then(response => {
                    this.invoices = response.data.slice(-5);
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        // getClients() {
        //     axios
        //         .get('/api/v1/clients/')
        //         .then(response => {
        //             for (let i = 0; i < response.data.length; i++) {
        //                 if (this.clients.length <= 5) {
        //                     this.clients.push(response.data[i])
        //                 }
        //             }
        //         })
        //         .catch(error => {
        //             console.log(JSON.stringify(error))
        //         })
        // }
    },
    computed: {
        unpaidInvoices() {
            return this.invoices ? this.invoices.filter(invoice => invoice.is_paid === false) : [];
        },
        paidInvoices() {
            return this.invoices ? this.invoices.filter(invoice => invoice.is_paid === true) : [];
        },
        limitedClients() {
            return this.clients.slice(0, 5);
        },   
    }
}
</script>