<template>
    <div class="page-client">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li><router-link to="/dashboard/clients">Clients</router-link></li>
                <li class="is-active"><router-link :to="{ name: 'Client', params: { id: client.id }}" aria-current="true">{{ client.name }}</router-link></li>
            </ul>
        </nav>
        
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">{{ client.name }}</h1>

                <router-link :to="{ name: 'EditClient', params: { id: client.id }}" class="button is-light mt-4">Edit</router-link>
            </div>

            <div class="column is-12">
                <h2 class="subtitle is-size-3 mb-3">{{ client.business_name }}</h2>
                <p><strong>{{ client.contact_person }}</strong></p>
                
                <p v-if="client.address1">{{ client.address1 }}</p>
                <p v-if="client.address2">{{ client.address2 }}</p>
                <p v-if="client.zipcode || client.place">{{ client.city }} {{ client.province }} {{ client.zipcode }}</p>
                <p v-if="client.country">{{ client.country }}</p>
            </div>

            <div class="column is-6">
                <div class="box">
                    <h2 class="subtitle">Recent Inbounds</h2>
                    <table class="table table-bordered is-fullwidth">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Warehouse</th>
                            <th>BOL #</th>
                            <!-- <th @click="sort('total_pallets')"># Pallets</th> -->
                            <th># Pallets</th>
                        </tr>
                    </thead>                 

                    <tbody>
                        <tr
                            v-for="inbound in inbounds"
                            v-bind:key="inbound.id"
                        >
                            <td>{{ getFormattedDate(inbound.created_at) }}</td>
                            <td>{{ inbound.warehouse_location }}</td>
                            <td>{{ inbound.bol_number }}</td>
                            <!-- <td>{{ inbound.client_commodity }}</td> -->
                            <td>{{ inbound.total_pallets }}</td>
                            <!-- <td>{{ inbound.total_boxes}}</td> -->
                            <!-- <td>{{ inbound }}</td> -->
                            <!-- <td>{{ getStatusLabel(invoice) }}</td> -->
                            <td>
                                <router-link :to="{ name: 'Inbound', params: { id: inbound.id }}">Details</router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>

            <div class="column is-6">
                <div class="box">
                    <h2 class="subtitle">Recent Outbounds</h2>

                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <!-- <th>#</th> -->
                                <th>Date</th>
                                <th>Driver</th>
                                <th>Pallets</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="outbound in this.outbounds"
                                v-bind:key="outbound.id"
                            >
                                <td>{{ getFormattedDate(outbound.created_at) }}</td>
                                <td>{{ outbound.driver_name }}</td>
                                <td>{{ outbound.dispatched_pallets.length }}</td>
                                <!-- <td>{{ outbound.get_due_date_formatted }}</td> -->
                                <td>
                                    <router-link :to="{ name: 'Outbound', params: { id: outbound.id }}">Details</router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <div class="column is-12" v-if="unpaidInvoices.length">
                <div class="box">
                    <h2 class="subtitle">Unpaid invoices</h2>

                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Amount</th>
                                <th>Due date</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="invoice in unpaidInvoices"
                                v-bind:key="invoice.id"
                            >
                                <td>{{ invoice.invoice_number }}</td>
                                <td>{{ invoice.gross_amount }}</td>
                                <td>{{ invoice.get_due_date_formatted }}</td>
                                <td>
                                    <router-link :to="{ name: 'Invoice', params: { id: invoice.id } }">Details</router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="column is-12" v-if="paidInvoices.length">
                <div class="box">
                    <h2 class="subtitle">Paid invoices</h2>

                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Amount</th>
                                <th>Due date</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="invoice in paidInvoices"
                                v-bind:key="invoice.id"
                            >
                                <td>{{ invoice.invoice_number }}</td>
                                <td>{{ invoice.gross_amount }}</td>
                                <td>{{ invoice.get_due_date_formatted }}</td>
                                <td>
                                    <router-link :to="{ name: 'Invoice', params: { id: invoice.id } }">Details</router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment';

export default {
    name: 'Client',
    data () {
        return {
            client: {
                invoices: [],
            },
            inbounds: [],
            outbounds: []
        }
    },
    mounted() {
        this.getClient(),
        this.getInbounds(),
        this.getOutbounds()
    },
    methods: {
        getClient() {
            const clientID = this.$route.params.id

            axios
                .get(`/api/v1/clients/${clientID}`)
                .then(response => {
                    this.client = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        getInbounds() {
            const clientID = this.$route.params.id

            axios
            .get(`/api/v1/inbounds/?client=${clientID}`)
                .then(response => {
                    this.inbounds = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        getOutbounds() {
            const clientID = this.$route.params.id

            axios
            .get(`/api/v1/outbounds/?client=${clientID}`)
                .then(response => {
                    this.outbounds = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        getFormattedDate(date) {
            return moment(date).format("DD-MM-YY hh:mm")
        },
    },
    computed: {
        unpaidInvoices() {
            return this.client && this.client.invoices ? this.client.invoices.filter(invoice => invoice.is_paid === false) : [];
        },
        paidInvoices() {
            return this.client && this.client.invoices ? this.client.invoices.filter(invoice => invoice.is_paid === true) : [];
        }   
    }

}
</script>