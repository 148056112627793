<template>
    <div class="page-add-invoice">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li><router-link to="/dashboard/invoices">Invoices</router-link></li>
                <li class="is-active"><router-link to="/dashboard/invoices/add" aria-current="true">Add</router-link></li>
            </ul>
        </nav>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Add invoice</h1>
            </div>

            <div class="column is-12">
                <h2 class="is-size-5 mb-4">Client</h2>

                <div class="select">
                    <select name="client" v-model="invoice.client">
                        <option value="">- Select client -</option>
                        <option 
                            v-for="client in clients"
                            v-bind:key="client.id"
                            v-bind:value="client"
                        >
                            {{ client.business_name }}
                        </option>
                    </select>
                </div>

                <div class="box mt-4" v-if="invoice.client">
                    <p><strong>{{ invoice.client.business_name }}</strong></p>
                    <p>{{invoice.client.contact_person }}</p>
                    <p>{{ invoice.client.address1 }}</p>
                    <p v-if="invoice.client.address2">{{ invoice.client.address2 }}</p>
                    <p>{{ invoice.client.city }} {{ invoice.client.province }} {{ invoice.client.zipcode}}</p>
                    <p>{{ invoice.client.phone }}</p>
                    <p>{{ invoice.client.email }}</p>
                </div>
            </div>

            <div class="column is-12">
                <h2 class="is-size-5 mb-4">Items</h2>

                <ItemForm
                    v-for="item in invoice.items"
                    v-bind:key="item.item_num"
                    v-bind:initialItem="item"
                    v-on:updatePrice="updateTotals"
                >
                </ItemForm>

                <button class="button is-light" @click="addItem">+</button>
            </div>

            <div class="column is-12">
                <h2 class="is-size-5 mb-4">Misc</h2>

                <div class="field">
                    <label>Due days</label>

                    <div class="control">
                        <input type="number" class="input" v-model="invoice.due_days">
                    </div>
                </div>

                <div class="field">
                    <label>Sender reference</label>

                    <div class="control">
                        <input type="text" class="input" v-model="invoice.sender_reference">
                    </div>
                </div>
            </div>
        </div>

        <div class="column is-12">
            <h2 class="is-size-5 mb-4">Total</h2>

            <p><strong>Net amount</strong>: {{ invoice.net_amount }}</p>
            <p><strong>Vat amount</strong>: {{ invoice.vat_amount }}</p>
            <p><strong>Gross amount</strong>: {{ invoice.gross_amount }}</p>
        </div>

        <div class="column is-12">
            <button class="button is-success" @click="submitForm">Save</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

import ItemForm from '@/components/ItemForm.vue'

export default {
    name: 'AddInvoice',
    components: {
        ItemForm
    },
    data() {
        return {
            invoice: {
                client: '',
                items: [
                    {
                        item_num: 0,
                        title: '',
                        unit_price: '',
                        quantity: 1,
                        vat_rate: 0,
                        net_amount: 0
                    }
                ],
                due_days: 14, 
                net_amount: 0,
                vat_amount: 0,
                gross_amount: 0
            },
            clients: []
        }
    },
    async mounted() {
        await this.getClients()
    },
    methods: {
        getClients() {
            axios
                .get('/api/v1/clients/')
                .then(response => {
                    this.clients = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        addItem() {
            this.invoice.items.push({
                item_num: this.invoice.items.length,
                title: '',
                unit_price: '',
                quantity: 1,
                vat_rate: 0,
                net_amount: 0
            })
        },
        updateTotals(changedItem) {
            let net_amount = 0
            let vat_amount = 0

            let item = this.invoice.items.filter(i => i.item_num === changedItem.item_num)

            console.log("item:", this.invoice.items);
            
            item = changedItem

            for (let i = 0; i < this.invoice.items.length; i++) {
                const vat_rate = this.invoice.items[i].vat_rate
                
                vat_amount += this.invoice.items[i].net_amount * (vat_rate / 100)
                net_amount += this.invoice.items[i].net_amount
            }

            this.invoice.net_amount = net_amount
            this.invoice.vat_amount = vat_amount
            this.invoice.gross_amount = net_amount + vat_amount
            this.invoice.discount_amount = 0
        },
        submitForm() {
            this.invoice.client_business_name = this.invoice.client.business_name
            this.invoice.client_contact_person = this.invoice.client.contact_person
            this.invoice.client_email = this.invoice.client.email
            this.invoice.client_org_number = this.invoice.client.org_number
            this.invoice.client_address1 = this.invoice.client.address1
            this.invoice.client_address2 = this.invoice.client.address2
            this.invoice.client_zipcode = this.invoice.client.zipcode
            this.invoice.client_city = this.invoice.client.city
            this.invoice.client_province = this.invoice.client.province
            this.invoice.client_country = this.invoice.client.country
            this.invoice.client_contact_person = this.invoice.client.contact_person
            this.invoice.client_contact_reference = this.invoice.client.contact_reference
            this.invoice.client = this.invoice.client.id

            axios
                .post('/api/v1/invoices/', this.invoice)
                .then(response => {
                    toast({
                        message: 'The invoice was added',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })

                    this.$router.push('/dashboard/invoices')
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        }
    }
}
</script>

<style lang="scss">
    .select, .select select {
        width: 100%;
    }
</style>