<template>
    <div class="page-add-client">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li><router-link to="/dashboard/clients">Customer</router-link></li>
                <li class="is-active"><router-link to="/dashboard/clients/add" aria-current="true">Add</router-link></li>
            </ul>
        </nav>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Add Customer</h1>
            </div>

            <div class="column is-12">
                <div  v-if="hasErrors" class="alert alert-danger">
                    <span v-for="(error, key) in errors" :key="key" style="color: red;">
                        <li v-for="(errorItem, errorKey) in error" :key="errorKey" class="text-danger">{{key}} : {{errorItem}}</li>
                    </span>
                </div>
            </div>

            <div class="column is-6">

                <div class="field">
                    <label class="required">Client Type: </label>
                    
                    <select class="control" v-model="client.client_type">
                        <option value="client" selected>Client</option>
                        <option value="consignee">Consignee</option>
                        <option value="both">Both (Client and Consignee)</option>
                    </select>
                </div>
                
                <div class="field">
                    <label class="required">Business Name</label>
                    
                    <div class="control">
                        <input type="text" name="business_name" class="input" v-model="client.business_name">
                    </div>
                </div>

                <div class="field">
                    <label>Business Number</label>
                    
                    <div class="control">
                        <input type="text" name="business_number" class="input" v-model="client.business_number" 
                        placeholder="XXXXXXXXX RC XXXX"
                        @input="acceptBusinessNumber">
                    </div>
                </div>

                <div class="field">
                    <label>GST/HST Number</label>
                    <div class="control">
                        <input type="text" name="gst_number" class="input" v-model="client.gst_number" 
                        placeholder="XXXXXXXXX RT XXXX"
                        @input="acceptGSTNumber">
                    </div>
                </div>

                <div class="field">
                    <label class="required">Contact Person</label>
                    <div class="control">
                        <input type="text" name="contact_person" class="input" v-model="client.contact_person">
                    </div>
                </div>                
                <div class="field">
                    <label class="required">Email</label>
                    
                    <div class="control">
                        <input type="email" name="email" class="input" v-model="client.email">
                    </div>
                </div>
                <div class="field">
                    <label class="required">Phone</label>
                    
                    <div class="control">
                        <input type="text" name="phone" class="input" v-model="client.phone"  @input="acceptPhoneNumber">
                    </div>
                </div>

                <br/>
                <div class="field">                   
                    <input type="checkbox" v-model="client.email_notifications">
                    <label> Send Confirmation Email to Client</label>
                </div>


            </div>

            <div class="column is-6">

                <div class="field">
                    <label>Auto-search address</label>
                    
                    <div class="control">
                        <vue-google-autocomplete
                        class="input"
                        ref="address"
                        id="map"
                        classname="form-control"
                        placeholder="Please type your address"
                        v-on:placechanged="getAddress"
                        country="ca"
                        ></vue-google-autocomplete>


                        <!-- <input type="text" name="address1" class="input" v-model="location.address1"> -->
                    </div>
                </div>

                <div class="field">
                    <label class="required">Address 1</label>
                    
                    <div class="control">
                        <input type="text" name="address1" class="input" v-model="client.address1">
                    </div>
                </div>

                <div class="field">
                    <label>Address 2</label>
                    
                    <div class="control">
                        <input type="text" name="address2" class="input" v-model="client.address2">
                    </div>
                </div>

                <div class="field">
                    <label class="required">City</label>
                    
                    <div class="control">
                        <input type="text" name="city" class="input" v-model="client.city">
                    </div>
                </div>

                <div class="field">
                    <label class="required">Province</label>
                    
                    <div class="control">
                        <input type="text" name="province" class="input" v-model="client.province">
                    </div>
                </div>

                <div class="field">
                    <label class="required">Postal Code</label>
                    
                    <div class="control">
                        <input type="text" name="zipcode" class="input" v-model="client.zipcode">
                    </div>
                </div>

                <div class="field">
                    <label class="required">Country</label>
                    
                    <div class="control">
                        <input type="text" name="country" class="input" v-model="client.country">
                    </div>
                </div>
            </div>

            <div class="column is-12">
                <div class="field">
                    <div class="control">
                        <button class="button is-success" @click="submitForm">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
  .required:after {
    content:" *";
    color: red;
  }
</style>
<script>
// import dropdown from 'vue-dropdowns';
import axios from 'axios'
import { toast } from 'bulma-toast'
import VueGoogleAutocomplete from 'vue-google-autocomplete';

export default {
    name: 'AddClient',
    data() {
        return {
            client: {client_type: 'client'},
            // email_confirmation: true,
            errors: {}
        }
    },
    components: {VueGoogleAutocomplete},
    computed: {
        hasErrors() {
            return Object.keys(this.errors).length > 0;
        }
    },
    methods: {
        submitForm() {
            // this.client.email_confirmation = this.email_confirmation;
            axios
                .post("/api/v1/clients/", this.client)
                .then(response => {
                    toast({
                        message: 'The client was added',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })

                    this.$router.push('/dashboard/clients')
                })
                .catch(error => {
                    this.errors = error.response.data;
                    console.log(JSON.stringify(error))
                })
        },
        acceptPhoneNumber() {
            var x = this.client.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.client.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        acceptBusinessNumber() {
            var x = this.client.business_number.replace(/\W/g, '').match(/(\d{0,9})([RC]{0,2})(\d{0,4})/);
            this.client.business_number = !x[2] ? x[1] : x[1] + ' ' + x[2] + (x[3] ? ' ' + x[3] : '');
        },
        acceptGSTNumber() {
            var x = this.client.gst_number.replace(/\W/g, '').match(/(\d{0,9})([RT]{0,2})(\d{0,4})/);
            this.client.gst_number = !x[2] ? x[1] : x[1] + ' ' + x[2] + (x[3] ? ' ' + x[3] : '');
        }, 
        getAddress(address, placeResultData, id){
            //this.address = address;
            // console.log(address);
            this.client.address1 = address.street_number + ' ' + address.route
            // this.client.address2 = address.administrative_area_level_1
            this.client.city = address.locality
            this.client.province = address.administrative_area_level_1
            this.client.zipcode = address.postal_code
            this.client.country = address.country
        },
        handleError(error){
            alert(error)
        }
    }
}
</script>