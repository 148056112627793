<template>
    <div class="page-add-inbound">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li><router-link to="/dashboard/inbounds">Inbounds</router-link></li>
                <li class="is-active"><router-link to="/dashboard/inbounds/add" aria-current="true">Add</router-link></li>
            </ul>
        </nav>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Add Inbound</h1>
            </div>

            <div class="column is-6">
                <h2 class="is-size-5 mb-4">Client</h2>

                <div class="select">
                    <select name="client" v-model="inbound.client">
                        <option value="">- Select client -</option>
                        <option 
                            v-for="client in clients"
                            v-bind:key="client.id"
                            v-bind:value="client"
                        >
                            {{ client.business_name }}
                        </option>
                    </select>
                </div>

                <div class="box mt-4" v-if="inbound.client">
                    <p><strong>{{ inbound.client.business_name }}</strong></p> 
                    <p>{{ inbound.client.contact_person }}</p>
                    <p>{{ inbound.client.address1 }}</p>
                    <p v-if="inbound.client.address2">{{ inbound.client.address2 }}</p>
                    <p>{{ inbound.client.city }} {{ inbound.client.province }} {{ inbound.client.zipcode}}</p>
                    <p>{{ inbound.client.phone }}</p>
                    <p>{{ inbound.client.email }}</p>
                </div>
            </div>

            <div class="column is-6">
                <h2 class="is-size-5 mb-4">Location</h2>

                <div class="select">
                    <select name="client" v-model="inbound.location">
                        <option value="">- Select location -</option>
                        <option 
                            v-for="location in locations"
                            v-bind:key="location.id"
                            v-bind:value="location"
                        >
                            {{ location.name }}
                        </option>
                    </select>
                    
                </div>

                <div class="box mt-4" v-if="inbound.location">
                    <p><strong>{{ inbound.location.name }}</strong></p>
                    <p>{{ inbound.location.address1 }}</p>
                    <p v-if="inbound.location.address2">{{ inbound.location.address2 }}</p>
                    <p>{{ inbound.location.city }} {{ inbound.location.province }} {{ inbound.location.zipcode }}</p>
                    <p>{{ inbound.location.email }}</p>
                    <p>{{ inbound.location.phone }}</p>
                </div>
            </div>
<!-- 
            <br/>
            <div style="padding-left: 10px;" class="field">                   
                <input type="checkbox" v-model="email_confirmation">
                <label> Send Confirmation Email to Client</label>
            </div> -->


            <div v-if="isSubmitting" class="loading-spinner"></div>
            <div class="column is-12">
                <h2 class="is-size-5 mb-4">Pallets</h2>
                <PalletForm
                    v-for="(pallet, index) in this.inbound.inbound_pallets"
                    v-bind:key="pallet.id"
                    v-bind:initialPallet="pallet"
                    v-bind:sequenceNumber="index + 1"
                    @updateBoxCount="updateTotals"
                    @deletePallet="handleDeletePallet(pallet)"
                >
                </PalletForm>
                <hr>
                <div class="columns is-mobile">
                    
                    <div class="column is-narrow">
                        <input type="number" class="input" style="width: 75px;" v-model="add_pallet_count">
                    </div>

                    <div class="column is-narrow">
                        <button class="button is-info" @click="addPallet"> copies of last pallet entry</button>
                    </div>
                    
                </div>
            </div>

            
            <div class="column is-6">
                <!-- <h1 class="is-size-5 mb-4">Summary</h1>                -->
                <div class="field">
                    <label>Transport Company:</label>

                    <div class="control">
                        <input type="text" class="input" v-model="inbound.transport_company">
                    </div>
                </div>
                <div class="field">
                    <label>Container/Truck #</label>
                    <div class="control">
                        <input type="text" class="input" v-model="inbound.truck_container_number">
                    </div>
                </div>
            </div>

            <div class="column is-6">       
                <div class="field">
                    <label>BOL #</label>
                    <div class="control">
                        <input type="text" class="input" v-model="inbound.bol_number">
                    </div>
                </div>
                <div class="field">
                    <label>Comment:</label>
                    <div class="control">
                        <input type="text" class="input" v-model="inbound.sender_reference">
                    </div>
                </div>
            </div>

        </div>

        <div class="column is-6">
                <h2 class="is-size-5 mb-4">Total</h2>
                <p><strong>Total pallets</strong>: {{ inbound.total_pallets }}</p>
                <p><strong>Total boxes</strong>: {{ inbound.total_boxes }}</p>
                <!-- <p><strong>Space usage</strong>: {{  }}</p> -->
        </div>



        <div class="column is-12">
            <button type="submit" :disabled="isSubmitting" class="button is-success" @click="submitForm">Submit</button>
        </div> 
        
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import PalletForm from '@/components/PalletForm.vue'

export default {
    name: 'AddInbound',
    components: {
        PalletForm
    },
    data() {
        return {
            inbound: {
                client: '',
                location:'',
                inbound_pallets: [
                    {
                        id: 0,
                        commodity: '',
                        box_count: 1
                    }
                ],
                driver_name: '', 
                driver_phone_number: '',
                sender_reference: '',
                warehouse_location: '',
                total_boxes: 1,
                total_pallets: 1
            },
            add_pallet_count: 1,
            clients: [],
            locations:[],
            // email_confirmation: true,
            isSubmitting: false,
        }
    },
    async mounted() {
        await this.getClients(),
        await this.getLocations()

    },
    methods: {
        getClients() {
            axios
                .get('/api/v1/clients/')
                .then(response => {
                    this.clients = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        getLocations() {
            axios
                .get('/api/v1/locations/')
                .then(response => {
                    // console.log(response.data);
                    for (let i = 0; i < response.data.length; i++) {
                        this.locations.push(response.data[i])
                    }
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        addPallet() {
            let pid = 0
            let commodity = ''
            let box_count = 64
            let pallets_count = this.inbound.inbound_pallets.length
            if ( pallets_count > 0) {
                for (let i = 0; i < this.inbound.inbound_pallets.length; i++) {
                    if (this.inbound.inbound_pallets[i].id > pid) {
                        pid = this.inbound.inbound_pallets[i].id;
                    }
                }
                box_count = this.inbound.inbound_pallets[pallets_count-1].box_count
                commodity = this.inbound.inbound_pallets[pallets_count-1].commodity
            }

            // console.log(this.inbound.inbound_pallets);
            for (let j = 0; j < this.add_pallet_count; j++) {
                pid = pid + 1
                this.inbound.inbound_pallets.push({
                    id: pid,
                    commodity: commodity,
                    box_count: box_count
                })
            }
            
            let new_box_count = 0
            for (let i = 0; i < this.inbound.inbound_pallets.length; i++) {
                new_box_count += this.inbound.inbound_pallets[i].box_count
            }
            this.inbound.total_boxes = new_box_count;
            this.inbound.total_pallets = this.inbound.inbound_pallets.length;
        },
        handleDeletePallet(deletedPallet) {
            this.inbound.inbound_pallets = this.inbound.inbound_pallets.filter(pallet => pallet.id !== deletedPallet.id);
            let new_box_count = 0
            for (let i = 0; i < this.inbound.inbound_pallets.length; i++) {
                new_box_count += this.inbound.inbound_pallets[i].box_count
            }
            this.inbound.total_pallets = this.inbound.inbound_pallets.length
            this.inbound.total_boxes =  new_box_count
        },
        updateTotals(changedPallet) {
            // console.log(changedPallet);

            let total_boxes = 0;
            
            // Update the item in this.invoice.items array or add it if not found
            const updatedPallets = this.inbound.inbound_pallets.map(pallet => {
                if (pallet.id === changedPallet.id) {
                    // console.log('matched item num:', pallet.id);
                    // console.log('Updating existing item:', changedPallet);
                    return changedPallet;
                }
                // console.log('Existing item:', pallet);
                return pallet;
            });

            // If the changedItem is not found in the existing items, add it
            if (!this.inbound.inbound_pallets.some(pallet => pallet.id === changedPallet.id)) {
                // console.log('Adding new item:', changedPallet);
                updatedPallets.push(changedPallet);
            }

            // Log the updatedItems array for further inspection
            // console.log('Updated items array:', updatedItems);

            // Assign the updatedItems array to this.invoice.items
            this.inbound.inbound_pallets = updatedPallets;

            // Recalculate totals
            this.inbound.inbound_pallets.forEach(pallet => {
                total_boxes += pallet.box_count;
            });
            
            this.inbound.total_boxes = total_boxes;
            this.inbound.total_pallets = this.inbound.inbound_pallets.length
        },
        submitForm() {
            this.isSubmitting = true;
            this.inbound.client_email_notification = this.inbound.client.email_notifications;

            this.inbound.client_business_name = this.inbound.client.business_name
            this.inbound.client_email = this.inbound.client.email
            this.inbound.client_org_number = this.inbound.client.org_number
            this.inbound.client_address1 = this.inbound.client.address1
            this.inbound.client_address2 = this.inbound.client.address2
            this.inbound.client_zipcode = this.inbound.client.zipcode
            this.inbound.client_city = this.inbound.client.city
            this.inbound.client_province = this.inbound.client.province
            this.inbound.client_country = this.inbound.client.country
            this.inbound.client_contact_person = this.inbound.client.contact_person
            this.inbound.client_contact_reference = this.inbound.client.contact_reference
            this.inbound.warehouse_location = this.inbound.location.name
            this.inbound.client = this.inbound.client.id
            this.inbound.location = this.inbound.location.id

            axios
                .post(`/api/v1/inbounds/`, this.inbound)
                .then(response => {
                    this.isSubmitting = false;
                    toast({
                        message: 'The inbound was added',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 4000,
                        position: 'bottom-right',
                    })

                    this.$router.push('/dashboard/inbounds')
                })
                .catch(error => {
                    this.isSubmitting = false;
                    console.error('Error updating inbound:', error);
                })
        }
    }
}
</script>

<style lang="scss">
    .select, .select select {
        width: 100%;
    }
</style>