<template>
    <div class="page-inbounds">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li class="is-active"><router-link to="/dashboard/outbounds" aria-current="true">Outbounds</router-link></li>
            </ul>
        </nav>

        <div class="columns is-multiline">
            <div class="column is-9">
                <button class="button is-success"> <router-link to="/dashboard/outbounds/add" class="button is-success">Add Outbound</router-link></button> 
            </div>
            
            <div class="column is-3">
                <input class="input is-small" type="search" v-model="filter" placeholder="Search outbounds by business name">
            </div>
            

            <div class="column is-12">
                
                <table class="table table-bordered is-fullwidth">
                    <thead>
                        <tr>
                            <th @click="sort('created_at')" >Date</th>
                            <!-- <th>Outbound ID</th> -->
                            <!-- <th @click="sort('created_at')" >Inbound ID</th> -->
                            <th @click="sort('client_business_name')">Business</th>
                            <th @click="sort('client_business_name')">Driver</th>
                            <th @click="sort('client_business_name')">Pallet ID</th>
                            <th @click="sort('total_boxes')"># Boxes</th>
                            <th @click="sort('total_pallets')">Commodity</th>
                            <!-- <th @click="sort('total_boxes')">Dispatched Boxes</th>                             -->
                            <th></th>
                        </tr>
                    </thead>                 

                    <tbody>
        
                        <tr
                            v-for="outbound in sortedOutbounds" 
                            v-bind:key="outbound.id"
                        >
                            <td>{{ getFormattedDate(outbound.created_at) }}</td>
                           
                            <td>{{ outbound.client_business_name}}</td>
                            <td>{{ outbound.carrier_driver_name}}</td>
                            <!-- <td>{{ pallet.inbound.client_business_name }}</td> -->
                            <!-- <td>{{ pallet.commodity }}</td> -->
                            <td>{{ outbound.pallet_ids }}</td>
                            <td>{{ outbound.boxes_dispatched }}</td>
                            <td>{{ outbound.commodity}}</td>
                            <!-- <input type="number" class="input" @input="() => { if(pallet.requested_boxes > pallet.available_boxes || pallet.requested_boxes < 1) { pallet.requested_boxes = pallet.available_boxes }}" v-model="pallet.requested_boxes"> -->
                            
                            <!-- <td><input type="checkbox" v-model="pallet.selected"></td> -->

                            <td>
                                <router-link :to="{ name: 'Outbound', params: { id: outbound.id }}">Details</router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>


        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment';


export default {
    name: 'Outbounds',
    data() {
        return {
            selectedClient: '',
            driver_name: '',
            driver_phone: '',
            clients: [],
            outbounds: [],
            inbounds: [],
            pallets: [],
            selectedClient: null,

            currentSort:'created_at',
            currentSortDir:'desc',
            pageSize:5,
            currentPage:1, 
            filter: ''
        }
    },
    mounted() {
        this.getOutbounds(),
        this.getClients()
    },
    methods: {
        getClients() {
            axios
                .get('/api/v1/clients/')
                .then(response => {
                    this.clients = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        // getOutbounds() {
        //     axios
        //         .get('/api/v1/outbounds/')
        //         .then(response => {
        //             this.outbounds = response.data;
        //         })
        //         .catch(error => {
        //             console.log(JSON.stringify(error))
        //         })
        // },

        async getOutbounds() {
            try {
                const response = await axios.get('/api/v1/outbounds/');
                for (let i = 0; i < response.data.length; i++) {
                    let outbound = response.data[i];
                    let pallet_ids = [];
                    let commodity = [];
                    let boxes_dispatched = [];
                    for (let j = 0; j < outbound.dispatched_pallets.length; j++) {
                        const plid = outbound.dispatched_pallets[j].pallet;
                        const palletResponse = await axios.get(`/api/v1/pallets/${plid}/`);
                        commodity.push(palletResponse.data.commodity);
                        pallet_ids.push(outbound.dispatched_pallets[j].pallet);
                        boxes_dispatched.push(outbound.dispatched_pallets[j].boxes_dispatched);
                    }
                    const clientResponse = await axios.get(`/api/v1/clients/${outbound.client}/`);
                    outbound['client_business_name'] = clientResponse.data.business_name;
                    outbound['commodity'] = commodity;
                    outbound['pallet_ids'] = pallet_ids;
                    outbound['boxes_dispatched'] = boxes_dispatched;
                    this.outbounds.push(outbound);
                }
            } catch (error) {
                console.log(JSON.stringify(error));
            }
        },
        dispatch() {
            // Get the pallet_ids
            // let pallet_ids = [];
            let selected_pallets = [];
            this.pallets.forEach(pallet => {
                // console.log(pallet.selected);
                if (pallet.selected) {
                    // pallet_ids.push(pallet.id)
                    selected_pallets.push({
                        pallet: pallet.id,
                        boxes_dispatched: pallet.requested_boxes
                    });
                }
            })
            // console.log(pallet_ids);
            
            let data = {
                'client': this.selectedClient.id,  
                "dispatched_pallets": selected_pallets,
                'driver_name': this.driver_name, 
                'driver_phone': this.driver_phone, 
                "client_reference": 'none', 
                "boxes_dispatched": 1
            }
            
            axios.post("/api/v1/outbounds/", data)
                .then(response => {
                    toast({
                        message: 'The client was added',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })

                    this.$router.push('/dashboard/clients')
                })
                .catch(error => {
                    this.errors = error.response.data;
                    console.log(JSON.stringify(error))
                });
        },
 
        getFormattedDate(date) {
            // console.log(date);
            return moment(date).format("DD-MM-YYYY")
        },
        getFormattedTime(date) {
            return moment(date).format("hhmm")
        },
        sort:function(s) {
            //if s == current sort, reverse
            if(s === this.currentSort) {
            this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
            }
            this.currentSort = s;
        },
        nextPage:function() {
            if((this.currentPage*this.pageSize) < this.inbounds.length) this.currentPage++;
        },
        prevPage:function() {
            if(this.currentPage > 1) this.currentPage--;
        },
        acceptPhoneNumber() {
            var x = this.client.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.client.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
    },
    computed:{
        sortedOutbounds:function() {
            return this.filteredOutbounds.sort((a,b) => {
                let modifier = 1;
                if(this.currentSortDir === 'desc') modifier = -1;
                if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                return 0;
            }).filter((row, index) => {
                let start = (this.currentPage-1)*this.pageSize;
                let end = this.currentPage*this.pageSize;
                if(index >= start && index < end) return true;
            });
        },
        filteredOutbounds() {
            return this.outbounds.filter(c => {
                if(this.filter == '') return true;
                return c.client_business_name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0;
            })
        },
        filteredPallets() {
            if (!this.selectedClient) {
                return [];
            }
            return this.pallets.filter(pallet => pallet.inbound.client === this.selectedClient.id);
        },
    },
}
</script>