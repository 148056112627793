<template>
    <div class="page-my-account">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li class="is-active"><router-link to="/dashboard/locations" aria-current="true">Locations</router-link></li>
            </ul>
        </nav>


        <div class="columns is-multiline">
            <div class="column is-6">
                <h1 class="title">{{team.business_name }} - Warehouse Locations</h1>
            </div>

            <div class="column is-12">
                <router-link :to="{ name: 'AddLocation' }" class="button is-light mt-4">Add Location</router-link>
            </div>
            
            <div
                class="column is-3"
                v-for="loc in locations"
                v-bind:key="loc.id"
            >
                <div class="box">
                    <h3 class="is-size-4 mb-4">{{ loc.name }}</h3>
                    <p>{{ loc.address1 }}</p>
                    <p>{{ loc.address2 }}</p>
                    <p>{{ loc.city }} {{ loc.province }} {{ loc.zipcode }}</p>
                    <!-- <p>{{ loc.country }}</p> -->
                    <p>{{ loc.email }}</p>
                    <p>{{ loc.phone }}</p>
                    <router-link :to="{ name: 'EditLocation', params: { id: loc.id }}" class="button is-light">Edit</router-link>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'MyAccount', 
    data() {
        return {
            team: {},
            locations: []
        }
    },
    async mounted() {
        await this.getOrCreateTeam(),
        await this.getLocations()
    },
    methods: {
        getOrCreateTeam() {
            axios
                .get('/api/v1/teams/')
                .then(response => {
                    this.team = response.data[0]
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        getLocations() {
            axios
                .get('/api/v1/locations/')
                .then(response => {
                    // console.log(response.data);
                    for (let i = 0; i < response.data.length; i++) {
                        this.locations.push(response.data[i])
                    }
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
    }
}
</script>