<template>
    <div class="page-edit-team">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li><router-link to="/dashboard/my-account">My account</router-link></li>
                <li class="is-active"><router-link to="/dashboard/my-account/edit-team" aria-current="true">Edit team</router-link></li>
            </ul>
        </nav>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Edit team</h1>
            </div>

            <div class="column is-6">
                <div class="field">
                    <label>Business Name</label>
                    <div class="control">
                        <input type="text" class="input" v-model="team.business_name">
                    </div>
                </div>

                <div class="field">
                    <label>Contact Person</label>
                    <div class="control">
                        <input type="text" class="input" v-model="team.contact_person">
                    </div>
                </div>

                <div class="field">
                    <label>Business Number</label>
                    <div class="control">
                        <input type="text" class="input" v-model="team.business_number">
                    </div>
                </div>

                <div class="field">
                    <label>GST Number</label>
                    <div class="control">
                        <input type="text" class="input" v-model="team.gst_number">
                    </div>
                </div>

                <div class="field">
                    <label>Email</label>
                    <div class="control">
                        <input type="email" class="input" v-model="team.email">
                    </div>
                </div>

                <div class="field">
                    <label>Email Notifications</label>
                    <div class="control">
                        <input type="checkbox" v-model="team.email_notifications">
                    </div>
                </div>

                <div class="field">
                    <label>Phone</label>
                    <div class="control">
                        <input type="email" class="input" v-model="team.phone">
                    </div>
                </div>
            </div>

                <!-- <div class="field">
                    <label>Bankaccount</label>
                    <div class="control">
                        <input type="text" class="input" v-model="team.bankaccount">
                    </div>
                </div> -->
            <div class="column is-6">
                

                <div class="field">
                    <label>Address</label>
                    <div class="control">
                        <input type="text" class="input" v-model="team.address1">
                    </div>
                </div>

                <div class="field">
                    <label>Address two</label>
                    <div class="control">
                        <input type="text" class="input" v-model="team.address2">
                    </div>
                </div>

                

                <div class="field">
                    <label>City</label>
                    <div class="control">
                        <input type="text" class="input" v-model="team.city">
                    </div>
                </div>

                <div class="field">
                    <label>Postal Code</label>
                    <div class="control">
                        <input type="text" class="input" v-model="team.zipcode">
                    </div>
                </div>

                <div class="field">
                    <label>Province</label>
                    <div class="control">
                        <input type="text" class="input" v-model="team.province">
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <button class="button is-success" @click="submitForm">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import { toast } from 'bulma-toast'

export default {
    name: 'EditTeam',
    data() {
        return {
            team: {}
        }
    },
    async mounted() {
        await this.getOrCreateTeam()
    },
    methods: {
        getOrCreateTeam() {
            axios
                .get('/api/v1/teams/')
                .then(response => {
                    this.team = response.data[0]
                    // console.log(this.team);
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        async submitForm() {

            const team = Object.assign({}, this.team);
            delete team['logo_image'];
            this.team = team
            axios
                .patch(`/api/v1/teams/${this.team.id}/`, this.team)
                .then(response => {
                    toast({
                        message: 'The changes was saved',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })

                    this.$router.push('/dashboard/my-account')
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        }
    }
}
</script>