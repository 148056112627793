
<template>
    <div class="page-add-location">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <!-- <li><router-link to="/dashboard/locations">locations</router-link></li> -->
                <li class="is-active"><router-link to="/dashboard/my-account/add-location" aria-current="true">Add</router-link></li>
            </ul>
        </nav>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Add location</h1>
            </div>

            <div class="column is-12">
                <div  v-if="hasErrors" class="alert alert-danger">
                    <span v-for="(error, key) in errors" :key="key" style="color: red;">
                        <li v-for="(errorItem, errorKey) in error" :key="errorKey" class="text-danger">{{key}}: {{errorItem}}</li>
                    </span>
                </div>
            </div>

            <div class="column is-6">
                <div class="field">
                    <label>Location name</label>
                    
                    <div class="control">
                        <input type="text" name="name" class="input" v-model="location.name">
                    </div>
                </div>

                <div class="field">
                    <label>Email</label>
                    
                    <div class="control">
                        <input type="email" name="email" class="input" v-model="location.email">
                    </div>
                </div>

                <div class="field">
                    <label>Phone</label>
                    
                    <div class="control">
                        <input type="text" name="phone" class="input" v-model="location.phone" @input="acceptPhoneNumber">
                    </div>
                </div>
                
                <div class="field">
                    <label>Auto-search address</label>
                    
                    <div class="control">
                        <vue-google-autocomplete
                        class="input"
                        ref="address"
                        id="map"
                        classname="form-control"
                        placeholder="Please type your address"
                        v-on:placechanged="getAddress"
                        country="ca"
                        ></vue-google-autocomplete>


                        <!-- <input type="text" name="address1" class="input" v-model="location.address1"> -->
                    </div>
                </div>

                <div class="field">
                    <label>Address</label>
                    
                    <div class="control">
                        <input type="text" name="address1" class="input" v-model="location.address1">
                    </div>
                </div>
            </div>

            <div class="column is-6">
                <div class="field">
                    <label>City</label>
                    
                    <div class="control">
                        <input type="text" name="place" class="input" v-model="location.city">
                    </div>
                </div>

                <div class="field">
                    <label>Province</label>
                    
                    <div class="control">
                        <input type="text" name="province" class="input" v-model="location.province">
                    </div>
                </div>

                <div class="field">
                    <label>Zipcode</label>
                    
                    <div class="control">
                        <input type="text" name="zipcode" class="input" v-model="location.zipcode">
                    </div>
                </div>

                <div class="field">
                    <label>Country</label>
                    
                    <div class="control">
                        <input type="text" name="country" class="input" v-model="location.country">
                    </div>
                </div>
            </div>

            <div class="column is-12">
                <div class="field">
                    <div class="control">
                        <button class="button is-success" @click="submitForm">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import VueGoogleAutocomplete from 'vue-google-autocomplete';

export default {
    name: 'AddLocation',
    data() {
        return {
            location: {},
            errors: {}
        }
    },
    //use as a component
    components: {VueGoogleAutocomplete},
    computed: {
        hasErrors() {
            return Object.keys(this.errors).length > 0;
        }
    },
    methods: {
        submitForm() {
            axios
                .post("/api/v1/locations/", this.location)
                .then(response => {
                    toast({
                        message: 'The location was added',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })

                    this.$router.push('/dashboard/my-account')
                })
                .catch(error => {
                    // console.log(JSON.stringify(error))
                    this.errors = error.response.data;
                })
        }, 
        acceptPhoneNumber() {
            var x = this.location.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.location.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        getAddress(address, placeResultData, id){
            //this.address = address;
            console.log(address);
            this.location.address1 = address.street_number + ' ' + address.route
            this.location.province = address.administrative_area_level_1
            this.location.city = address.administrative_area_level_2
            this.location.zipcode = address.postal_code
            this.location.country = address.country
        },
        handleError(error){
            alert(error)
        }
    }
}
</script>