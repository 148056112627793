import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/dashboard/Dashboard.vue'
import MyAccount from '../views/dashboard/MyAccount.vue'
import SignUp from '../views/SignUp.vue'
import LogIn from '../views/LogIn.vue'
import Locations from '../views/dashboard/Locations.vue'

import Clients from '../views/dashboard/Clients.vue'
import Client from '../views/dashboard/Client.vue'
import AddClient from '../views/dashboard/AddClient.vue'
import EditClient from '../views/dashboard/EditClient.vue'
import EditTeam from '../views/dashboard/EditTeam.vue'
import UploadTeamLogo from '../views/dashboard/UploadTeamLogo.vue'
import AddLocation from '../views/dashboard/AddLocation.vue'
import EditLocation from '../views/dashboard/EditLocation.vue'

import Invoices from '../views/dashboard/Invoices.vue'
import Invoice from '../views/dashboard/Invoice.vue'
import AddInvoice from '../views/dashboard/AddInvoice.vue'
import EditInvoice from '../views/dashboard/EditInvoice.vue'

import Inbounds from '../views/dashboard/Inbounds.vue'
import Inbound from '../views/dashboard/Inbound.vue'
import AddInbound from '../views/dashboard/AddInbound.vue'
// import EditInbound from '../views/dashboard/EditInbound.vue'

import Outbounds from '../views/dashboard/Outbounds.vue'
import Outbound from '../views/dashboard/Outbound.vue'
import AddOutbound from '../views/dashboard/AddOutbound.vue'
import EditOutbound from '../views/dashboard/Outbound.vue'

import store from '../store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/log-in',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/inbounds',
    name: 'Inbounds',
    component: Inbounds,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/inbounds/add',
    name: 'AddInbound',
    component: AddInbound,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/inbounds/:id',
    name: 'Inbound',
    component: Inbound,
    meta: {
      requireLogin: true
    }
  },
  // {
  //   path: '/dashboard/inbounds/:id/edit',
  //   name: 'EditInbound',
  //   component: EditInbound,
  //   meta: {
  //     requireLogin: true
  //   }
  // },
  {
    path: '/dashboard/outbounds',
    name: 'Outbounds',
    component: Outbounds,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/outbounds/add',
    name: 'AddOutbound',
    component: AddOutbound,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/outbounds/:id',
    name: 'Outbound',
    component: Outbound,
    meta: {
      requireLogin: true
    }
  },

  {
    path: '/dashboard/invoices',
    name: 'Invoices',
    component: Invoices,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/invoices/add',
    name: 'AddInvoice',
    component: AddInvoice,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/invoices/:id',
    name: 'Invoice',
    component: Invoice,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/invoices/:id/edit',
    name: 'EditInvoice',
    component: EditInvoice,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/clients',
    name: 'Clients',
    component: Clients,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/clients/add',
    name: 'AddClient',
    component: AddClient,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/clients/:id',
    name: 'Client',
    component: Client,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/clients/:id/edit',
    name: 'EditClient',
    component: EditClient,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/my-account',
    name: 'MyAccount',
    component: MyAccount,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/locations',
    name: 'Locations',
    component: Locations,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/my-account/edit-team',
    name: 'EditTeam',
    component: EditTeam,
    meta: {
      requireLogin: true
    }
  },

  {
    path: '/dashboard/my-account/upload-team-logo',
    name: 'UploadTeamLogo',
    component: UploadTeamLogo,
    meta: {
      requireLogin: true
    }
  },


  {
    path: '/dashboard/my-account/add-location',
    name: 'AddLocation',
    component: AddLocation,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/locations/:id/edit',
    name: 'EditLocation',
    component: EditLocation,
    meta: {
      requireLogin: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next('/log-in')
  } else {
    next()
  }
})

export default router
