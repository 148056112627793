<template>
    <div class="columns">
        <div class="column is-3">
            <div class="field">
                <label>Title</label>
                <div class="control">
                    <input type="text" class="input" v-model="item.title">
                </div>
            </div>
        </div>

        <div class="column is-2">
            <div class="field">
                <label>Price</label>
                <div class="control">
                    <input type="text" class="input" v-model="item.unit_price">
                </div>
            </div>
        </div>

        <div class="column is-2">
            <div class="field">
                <label>Quantity</label>
                <div class="control">
                    <input type="number" class="input" v-model="item.quantity">
                </div>
            </div>
        </div>

        <div class="column is-2">
            <div class="field">
                <label>GST</label>
                <div class="control">
                    <div class="select">
                        <select v-model="item.vat_rate">
                            <option value="13">13%</option>
                            <option value="0">0%</option>
                            <!-- <option value="25">15%</option> -->
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="column is-2">
            <div class="field">
                <label>Gross amount</label>
                <div class="control">
                    <input type="text" class="input" v-bind:value="gross_amount" disabled>
                </div>
            </div>
        </div>

        <div class="column is-1">
            <button class="button is-danger" @click="deleteItem"><svg-icon type="mdi" :path="path"></svg-icon></button>
        </div>
    </div>
</template>

<style scoped>
  /* Align columns vertically using Flexbox */
  .columns {
    display: flex;
    align-items: flex-end;
  }

  /* Optional: Adjust the spacing between text boxes and the delete button */
  .column:not(:last-child) {
    margin-right: 1rem;
  }
</style>

<script>
import {mdiDelete } from '@mdi/js'
import SvgIcon from '@jamescoyle/vue-icon'

export default {
    name: 'ItemForm',
    components: {
        SvgIcon
    },
    props: {
        initialItem: Object
    },
    data() {
        return {
            item: this.initialItem,
            path: mdiDelete
        }
    },
    computed: {
        gross_amount() {
            const unit_price = this.item.unit_price
            const quantity = this.item.quantity
            const vat_rate = this.item.vat_rate

            this.item.net_amount = unit_price * quantity

            this.$emit('updatePrice', this.item)

            return this.item.net_amount + (this.item.net_amount * (vat_rate / 100))
        }
    },
    methods: {
        deleteItem() {
        // Emit an event to notify the parent component that this item should be deleted
        this.$emit('deleteItem', this.item);
        }
    }
}
</script>