<template>
    <div class="page-my-account">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li class="is-active"><router-link to="/dashboard/my-account" aria-current="true">{{team.business_name}} account</router-link></li>
            </ul>
        </nav>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Upload Team Logo</h1>
                <h2 class="subtitle is-size-3 mb-3">{{team.business_name }}</h2>
            </div>

            <form @submit.prevent="uploadImage">
            <input type="file" @change="onFileChange" />
            <button type="submit">Upload</button>
            </form>
            <p v-if="message">{{ message }}</p>

        </div>

    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'MyAccount', 
    data() {
        return {
            team: {},
            selectedFile: null,
            message: '',
        }
    },
    async mounted() {
        await this.getOrCreateTeam()  
    },
    methods: {
        getOrCreateTeam() {
            axios
                .get('/api/v1/teams/')
                .then(response => {
                    this.team = response.data[0]
                    console.log(this.team);
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },

        onFileChange(event) {
            this.selectedFile = event.target.files[0];
        },
        
        async uploadImage() {
            if (!this.selectedFile) {
                this.message = 'Please select a file first.';
                return;
            }

            // Create a new file with the desired name
            const fileExtension = this.selectedFile.name.split('.').pop();
            const newFileName = `logo_team_${this.team.id}.${fileExtension}`;
            const renamedFile = new File([this.selectedFile], newFileName, { type: this.selectedFile.type });


            const formData = new FormData();
            formData.append('logo_image', renamedFile);
            formData.append('business_name', this.team.business_name);
            formData.append('contact_person', this.team.contact_person);

            try {
                const response = await axios.patch(`/api/v1/teams/${this.team.id}/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                this.message = 'Image uploaded successfully!';
            } catch (error) {
                this.message = 'Failed to upload image.';
                console.error(error);
            }
        }
    }
};

</script>