<template>
    <div class="page-inbounds">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li class="is-active"><router-link to="/dashboard/outbounds" aria-current="true">Outbounds</router-link></li>
            </ul>
        </nav>

        <div class="columns is-multiline">
            <!-- <div class="column is-12">
                <h1 class="title">Pallets Details</h1> 
            </div> -->
            

            <div class="column is-3">
                <h2 class="title">Client</h2>

                <div class="select">
                    <select name="client" v-model="selectedClient">
                        <option value="">- Select client -</option>
                        <option 
                            v-for="client in clients"
                            v-bind:key="client.id"
                            v-bind:value="client"
                        >
                            {{ client.business_name }}
                        </option>
                    </select>
                </div>

                <div class="box mt-4" v-if="selectedClient">
                    
                    <h3 class="subtitle is-size-4 mb-4">{{ selectedClient.business_name }} > {{ selectedClient.contact_person }}</h3>
                    <p v-if="selectedClient.address1">{{ selectedClient.address1 }}</p>
                    <p v-if="selectedClient.zipcode || selectedClient.place">{{ selectedClient.city }} {{ selectedClient.province }} {{ selectedClient.zipcode }}</p>
                    <p>{{ selectedClient.phone }}</p>
                    <p>{{ selectedClient.email }}</p>
                </div>
            </div>

            <div class="column is-3">
                <h2 class="title">Consignee</h2>

                <div class="select">
                    <select name="consignee" v-model="selectedConsignee">
                        <option value="">- Select Consignee -</option>
                        <option 
                            v-for="client in clients"
                            v-bind:key="client.id"
                            v-bind:value="client"
                        >
                            {{ client.business_name }}
                        </option>
                    </select>
                </div>

                <div class="box mt-4" v-if="selectedConsignee">
                    <h3 class="subtitle is-size-4 mb-4">{{ selectedConsignee.business_name }} > {{ selectedConsignee.contact_person }} </h3>
                    <p v-if="selectedConsignee.address1">{{ selectedConsignee.address1 }}</p>
                    <p v-if="selectedConsignee.zipcode || selectedConsignee.place">{{ selectedConsignee.city }} {{ selectedConsignee.province }} {{ selectedConsignee.zipcode }}</p>
                    <p>{{ selectedConsignee.phone }}</p>
                    <p>{{ selectedConsignee.email }}</p>
                </div>
            </div>

            <div class="column is-3">
                <h2 class="title">Carrier</h2>
                <div class="field">
                    <label class="required">Company Name</label>
                    <div class="control">
                        <input type="text" name="company_name" class="input" v-model="carrier.company_name">
                    </div>
                </div>
                <div class="field">
                    <label class="required">Vehicle No.</label>
                    <div class="control">
                        <input type="text" name="vehicle_number" class="input" v-model="carrier.vehicle_number">
                    </div>
                </div>
                <div class="field">
                    <label class="required">Driver</label>
                    <div class="control">
                        <input type="text" name="driver_name" class="input" v-model="carrier.driver_name">
                    </div>
                </div>
                <div class="field">
                    <label class="required">Driver's Phone</label>
                    <div class="control">
                        <input type="text" name="driver_phone" class="input" v-model="carrier.driver_phone"  @input="acceptPhoneNumber">
                    </div>
                </div>
                <div class="field">
                    <label class="required">POC</label>
                    <div class="control">
                        <input type="text" name="poc" class="input" v-model="carrier.poc"  @input="acceptPhoneNumber">
                    </div>
                </div>


                


            </div>
            <div class="column is-3">
                <h2 class="title">Delivery Address</h2>

                <div class="field">
                    <label class="required">Company Name</label>
                    
                    <div class="control">
                        <input type="text" name="company_name" class="input" v-model="delivery_address.company_name">
                    </div>
                </div>

                <div class="field">
                    <label>Auto-search address</label>
                    
                    <div class="control">
                        <vue-google-autocomplete
                        class="input"
                        ref="address"
                        id="map"
                        classname="form-control"
                        placeholder="Please type your address"
                        v-on:placechanged="getAddress"
                        country="ca"
                        ></vue-google-autocomplete>


                        <!-- <input type="text" name="address1" class="input" v-model="location.address1"> -->
                    </div>
                </div>

                <div class="field">
                    <label class="required">Address</label>
                    
                    <div class="control">
                        <input type="text" name="address1" class="input" v-model="delivery_address.address1">
                    </div>
                </div>

                <!-- <div class="field">
                    <label>Address 2</label>
                    
                    <div class="control">
                        <input type="text" name="address2" class="input" v-model="delivery_address.address2">
                    </div>
                </div> -->

                <div class="field">
                    <label class="required">City</label>
                    
                    <div class="control">
                        <input type="text" name="city" class="input" v-model="delivery_address.city">
                    </div>
                </div>

                <div class="field">
                    <label class="required">Province</label>
                    
                    <div class="control">
                        <input type="text" name="province" class="input" v-model="delivery_address.province">
                    </div>
                </div>

                <div class="field">
                    <label class="required">Postal Code</label>
                    
                    <div class="control">
                        <input type="text" name="zipcode" class="input" v-model="delivery_address.zipcode">
                    </div>
                </div>

                <!-- <div class="field">
                    <label class="required">Country</label>
                    
                    <div class="control">
                        <input type="text" name="country" class="input" v-model="delivery_address.country">
                    </div>

                </div> -->

            </div>

            <div class="column is-4">
                <h2 class="title">Instructions</h2>

                <div class="field">
                    <label class="required">Pickup Date/Time</label>
                    
                    <div class="control">
                        <input type="date" name="pickup_dt" class="input" v-model="instructions.pickup_dt">
                    </div>
                </div>
                <div class="field">
                    <label class="required">Delivery Date/Time</label>
                    
                    <div class="control">
                        <input type="date" name="delivery_dt" class="input" v-model="instructions.delivery_dt">
                    </div>
                </div>

                <div class="field">
                    <label class="required">Dock</label>
                    
                    <div class="control">
                        <input type="text" name="dock" class="input" v-model="instructions.dock">
                    </div>
                </div>
                
                <div class="field">
                    
                    <div class="control">
                        Tailgate (Y/N): <input type="checkbox" name="tailgate" v-model="instructions.tailgate">
                    </div>
                </div>

            </div>

            <div class="column is-4">
                <h2 class="title">Freight Charges</h2>
                <div class="field">
                    <div class="control">
                        Prepaid: <input type="checkbox" name="prepaid"  v-model="freight_charges.prepaid">
                    </div>
                </div>

                <div class="field">
                    <label class="required">COLLECT (COD)</label>
                    
                    <div class="control">
                        <input type="text" name="collect_cod" class="input" v-model="freight_charges.collect_cod">
                    </div>
                </div>

                <div class="field">
                    <label class="required">3rd Party</label>
                    
                    <div class="control">
                        <input type="text" name="third_party" class="input" v-model="freight_charges.third_party">
                    </div>
                </div>
            </div>

            <div class="column is-9">
                <button class="button is-success" @click="dispatch"> Submit </button> 
            </div>

            <div class="column is-3">
                <input class="input is-small" type="search" v-model="filter" placeholder="Search inbounds by business name">
            </div>
            

            <div class="column is-12">
                
                <table class="table table-bordered is-fullwidth">
                    <thead>
                        <tr>
                            <th @click="sort('created_at')" >Inbound Date</th>
                            <th @click="sort('created_at')" >Inbound ID</th>
                            <th @click="sort('client_business_name')">Business</th>
                            <th @click="sort('total_pallets')">Commodity</th>
                            <th @click="sort('total_boxes')">Available Boxes</th>
                            <!-- <th @click="sort('total_boxes')">Dispatched Boxes</th>                             -->
                            <th @click="sort('total_boxes')">Dispatch # Boxes</th>
                            <th>Select</th>
                        </tr>
                    </thead>                 

                    <tbody>
                        <tr
                            v-for="pallet in this.filteredPallets" v-bind:key="pallet.id"
                        >
                            <td>{{ getFormattedDate(pallet.inbound.created_at) }}</td>
                            <td>VB{{ getFormattedTime(pallet.inbound.created_at)}}IN{{pallet.inbound.id}}-{{ pallet.label_id +1 }}</td>
                            <td>{{ pallet.inbound.client_business_name }}</td>
                            <td>{{ pallet.commodity }}</td>
                            <td>{{ pallet.available_boxes }} / {{ pallet.box_count }}</td>
                            <input type="number" class="input" @input="() => { if(pallet.requested_boxes > pallet.available_boxes || pallet.requested_boxes < 1) { pallet.requested_boxes = pallet.available_boxes }}" v-model="pallet.requested_boxes">
                            
                            <td><input type="checkbox" v-model="pallet.selected"></td>
                        </tr>
                    </tbody>
                </table>
            </div>


        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment';
import VueGoogleAutocomplete from 'vue-google-autocomplete';

export default {
    name: 'Inbouds',
    data() {
        return {
            selectedConsignee: '',
            selectedClient: '',
            driver_name: '',
            driver_phone: '',
            clients: [],
            inbounds: [],
            pallets: [],
            selectedClient: null,
            outbound: {},
            delivery_address: {},
            freight_charges: {},
            carrier:{},
            instructions: {},

            currentSort:'created_at',
            currentSortDir:'desc',
            pageSize:5,
            currentPage:1, 
            filter: ''
        }
    },
    components: {VueGoogleAutocomplete},
    mounted() {
        this.getInbounds(),
        this.getClients()
    },
    methods: {
        getClients() {
            axios
                .get('/api/v1/clients/')
                .then(response => {
                    this.clients = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        getInbounds() {
            axios
                .get('/api/v1/inbounds/')
                .then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        let inbound = response.data[i]
                        
                        // Loop through the 'pallets' list within each 'inbound'
                        for (let j = 0; j < inbound.inbound_pallets.length; j++) {
                            let pallet = inbound.inbound_pallets[j];
                            // Do something with each pallet, for example, push it to an array
                            pallet['inbound'] = inbound
                            pallet['available_boxes'] = pallet.box_count - pallet.total_boxes_dispatched
                            pallet['requested_boxes'] = pallet['available_boxes']
                            pallet['label_id'] = j
                            if (pallet['available_boxes'] > 0){
                                this.pallets.push(pallet);
                            }
                            // console.log(pallet);
                        }
                        this.inbounds.push(inbound)
                    }
                    
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        dispatch() {
            // Get the pallet_ids
            // let pallet_ids = [];
            let selected_pallets = [];
            this.pallets.forEach(pallet => {
                if (pallet.selected) {
                    selected_pallets.push({
                        pallet: pallet.id,
                        boxes_dispatched: pallet.requested_boxes
                    });
                }
            })
            this.outbound.client_email_notification = this.selectedClient.email_notifications
            this.outbound.client =  this.selectedClient.id
            this.outbound.dispatched_pallets = selected_pallets
            this.outbound.client_reference = 'none' 

            this.outbound.consignee = this.selectedConsignee.id

            this.outbound.carreir_company = this.carrier.company_name
            this.outbound.carrier_vehicle_number = this.carrier.vehicle_number
            this.outbound.carreir_driver_name = this.carrier.driver_name
            this.outbound.carrier_driver_phone = this.carrier.driver_phone
            this.outbound.carreir_poc = this.carrier.poc
            
            this.outbound.delivery_business_name = this.delivery_address.company_name
            this.outbound.delivery_address1 = this.delivery_address.address1
            this.outbound.delivery_zipcode = this.delivery_address.zipcode
            this.outbound.delivery_city = this.delivery_address.city
            this.outbound.delivery_province = this.delivery_address.province

            this.outbound.freight_is_paid = this.freight_charges.prepaid
            this.outbound.freight_collect_cod = this.freight_charges.collect_cod
            this.outbound.freight_third_party = this.freight_charges.third_party

            this.outbound.instructions_tailgate = this.instructions.tailgate
            this.outbound.instructions_dock = this.instructions.dock
            this.outbound.instructions_pick_dt = this.instructions.pickup_dt
            this.outbound.instructions_delivery_dt = this.instructions.delivery_dt
            
            axios.post("/api/v1/outbounds/", this.outbound)
                .then(response => {
                    toast({
                        message: 'The dispatch was added',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })

                    this.$router.push('/dashboard/outbounds')
                })
                .catch(error => {
                    
                    console.log(JSON.stringify(error))
                    this.$router.push('/dashboard/outbounds')
                });
        },
 
        getFormattedDate(date) {
            return moment(date).format("DD-MM-YYYY")
        },
        getFormattedTime(date) {
            return moment(date).format("hhmm")
        },
        sort:function(s) {
            //if s == current sort, reverse
            if(s === this.currentSort) {
            this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
            }
            this.currentSort = s;
        },
        nextPage:function() {
            if((this.currentPage*this.pageSize) < this.inbounds.length) this.currentPage++;
        },
        prevPage:function() {
            if(this.currentPage > 1) this.currentPage--;
        },
        acceptPhoneNumber() {
            var x = this.driver_phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.driver_phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        getAddress(address, placeResultData, id){
            this.address = address;
            console.log(address);
            this.delivery_address.address1 = address.street_number + ' ' + address.route
            // this.client.address2 = address.administrative_area_level_1
            this.delivery_address.city = address.locality
            this.delivery_address.province = address.administrative_area_level_1
            this.delivery_address.zipcode = address.postal_code
            // this.client.country = address.country
        },
    },
  
    computed:{
        sortedInbounds:function() {
            return this.filteredInbounds.sort((a,b) => {
                let modifier = 1;
                if(this.currentSortDir === 'desc') modifier = -1;
                if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                return 0;
            }).filter((row, index) => {
                let start = (this.currentPage-1)*this.pageSize;
                let end = this.currentPage*this.pageSize;
                if(index >= start && index < end) return true;
            });
        },
        filteredInbounds() {
            return this.inbounds.filter(c => {
                if(this.filter == '') return true;
                return c.client_business_name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0;
            })
        },
        filteredPallets() {
            if (!this.selectedClient) {
                return [];
            }
            return this.pallets.filter(pallet => pallet.inbound.client === this.selectedClient.id);
        },
        
    },
}
</script>